import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import TableEventsTools from '@/components/Events/TableEventsTools'
import CustomInput from '@/components/CustomInput'
import Table from '@/components/Events/Table'
import { Paginate } from '@/components/Paginate'
import Header from '@/components/Header'

import { $axios } from '@/shared/api'

import './Events9Inch.css'
import LoaderFullScreen from '@/components/AppHelpers/LoaderFullScreen'
import ModalWithTitle from '@/components/ModalWithTitle'
import FormCheckedStatus from '@/components/AppHelpers/FormCheckedStatus'

interface IEventsOnPageInfo {
  count: number
  page: number
  pages: number
  per_page: number
}

type TEventsData = {
  pagination: IEventsOnPageInfo
  results: any[]
}

const Events9Inch = () => {
  const [checkboxValues, setCheckboxValues] = useState<string[]>([])
  const [data, setData] = useState<TEventsData | null>(null)
  const nowDate = new Date()
    .toISOString()
    .slice(0, 10)
    .split('-')
    .reverse()
    .join('.')
  const [currentEvent, setCurrentEvent] = useState('')
  const [date, setDate] = useState(nowDate)
  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(false)

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const pageIndex = Number(query.get('page'))
  const device_id = query.get('device_id')
  const filter = query.get('filter')

  const fetchData = async (
    page: number,
    perPage: number,
    date: string,
    checkboxValues: any,
  ) => {
    try {
      const response = await $axios.get(
        `/api/events?page=${page}&per-page=${perPage}&date=${date}${
          checkboxValues ? `&status=${checkboxValues.join(',')}` : ''
        }${filter ? `&filter=${filter}` : ''}${
          device_id ? `&device_id=${device_id}` : ''
        }`,
      )

      setData(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(true)
    }
  }

  useEffect(() => {
    if (date.length === 10) {
      fetchData(pageIndex, 5, date, checkboxValues)
    }
  }, [pageIndex, date, checkboxValues, filter, device_id])

  const prevPage = useCallback(() => {
    const prevIndex = pageIndex - 1

    if (prevIndex >= 1)
      navigate(
        `/events?page=${prevIndex}${
          checkboxValues.length ? `&status=${checkboxValues.join(',')}` : ''
        }${filter ? `&filter=${filter}` : ''}${
          device_id ? `&device_id=${device_id}` : ''
        }`,
      )
  }, [pageIndex, checkboxValues, filter, device_id])

  const nextPage = useCallback(() => {
    const nextIndex = pageIndex + 1

    if (data)
      if (nextIndex <= data.pagination.count)
        navigate(
          `/events?page=${nextIndex}${
            checkboxValues ? `&status=${checkboxValues.join(',')}` : ''
          }${filter ? `&filter=${filter}` : ''}${
            device_id ? `&device_id=${device_id}` : ''
          }`,
        )
  }, [pageIndex, data, checkboxValues, filter, device_id])

  function onChangeDateInput(name: string, value: string) {
    if (!name) {
      return
    }

    if (!date && !value) {
      return
    }

    // backspace
    if (value.length < date.length) {
      // delete points in mask
      if (value.length === 3 || value.length === 6) {
        setDate(value.slice(0, -1))
        return
      }

      setDate(value)
      return
    }

    // input
    const clearValue = value.replace(/\D/g, '')
    const formattedValue = `${clearValue.slice(0, 2)}.${clearValue.slice(
      2,
      4,
    )}.${clearValue.slice(4, 8)}`
    setDate(formattedValue)
  }

  const [isShow, setShow] = useState(false)
  const showAllEvents = () => {
    setCheckboxValues([])
  }

  const setupFilter = () => {
    toggleModal()
  }

  const toggleModal = () => {
    setShow(state => !state)
  }

  const getSelectedValues = (array: string[]) => {
    toggleModal()
    setCheckboxValues(array)
  } 

  if (isLoading) return <LoaderFullScreen />
  if (isError) return <p>Возникла непредвиденная ошибка</p>

  return (
    <>
      <Header
        title='журнал событий пожарной системы'
        subtitle='для детального просмотра события нажмите на кнопку Подробнее'
      />

      {!data?.results ? (
        <p>Нет ни одного события</p>
      ) : (
        <>
          <div className='table-wrapper'>
            <CustomInput
              value={date}
              name='number'
              type='date'
              title='Выберите дату'
              placeholder='укажите дату'
              onChange={onChangeDateInput}
              style={{ width: 236 }}
            />

            <Table
              events={data.results}
              total={data.pagination.count}
              currentEvent={currentEvent}
              setCurrentEvent={setCurrentEvent}
            />
            <TableEventsTools
              showAllEvents={showAllEvents}
              setupFilter={setupFilter}
              countCurrentStatuses={checkboxValues.length}
              currentEvent={currentEvent}
            />
          </div>
          <Paginate
            perPage={data.pagination.per_page}
            totalPage={data.pagination.pages}
            currentPage={data.pagination.page}
            viewedElements={data.results.length}
            totalElements={data.pagination.count}
            prevPage={prevPage}
            nextPage={nextPage}
          />
          <ModalWithTitle
            isOpen={isShow}
            title='выберите категории событий'
            content={
             <FormCheckedStatus checkboxValues={checkboxValues} getSelectedValues={getSelectedValues} />
            }
            closeModal={toggleModal}
          />
        </>
      )}
    </>
  )
}

export default Events9Inch
