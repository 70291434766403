import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import CustomInput from '@/components/CustomInput'
import Table from '@/components/Events/Table'
import Header from '@/components/Header'

import { $axios } from '@/shared/api'
import './Events8Inch.css'
import LoaderFullScreen from '@/components/AppHelpers/LoaderFullScreen'
import ModalWithTitle from '@/components/ModalWithTitle'
import FormCheckedStatus from '@/components/AppHelpers/FormCheckedStatus'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loader } from '@/components/AppHelpers/Loader'

interface IEventsOnPageInfo {
  count: number
  page: number
  pages: number
  per_page: number
}

type TEventsData = {
  pagination: IEventsOnPageInfo
  results: any[]
}

const Events8Inch = () => {
  const LIMIT = 8
  const navigate = useNavigate()
  const [hasMore, setHasMore] = useState(true)
  const [checkboxValues, setCheckboxValues] = useState<string[]>([])
  const [data, setData] = useState<TEventsData | null>(null)
  const nowDate = new Date()
    .toISOString()
    .slice(0, 10)
    .split('-')
    .reverse()
    .join('.')
  const [date, setDate] = useState(nowDate)
  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(false)

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const [pageIndex, setPageIndex] = useState(1)
  const device_id = query.get('device_id')
  const filter = query.get('filter')

  const fetchPage = () => {
    $axios
      .get(
        `/api/events?page=${pageIndex}&per-page=${LIMIT}&date=${date}${
          checkboxValues ? `&status=${checkboxValues.join(',')}` : ''
        }${filter ? `&filter=${filter}` : ''}${
          device_id ? `&device_id=${device_id}` : ''
        }`,
      )
      .then(response => {
        const oldItems = data?.results

        if (oldItems) {
          setData((prevState: any) => ({
            ...prevState,
            results: [...oldItems, ...response.data.results],
          }))
        } else {
          setData(response.data)
        }

        setLoading(false)
      })
      .catch(error => {
        const oldItems = data?.results

        if (oldItems) {
          setHasMore(false)
        } else {
          setLoading(false)
          setError(true)
        }
      })
      .finally(() => {
        setPageIndex(pageIndex + 1)
      })
  }

  const fetchNextPage = () => {
    // ПРОВЕРКА: нужно ли подгружать дальше данные или мы уже всё получили
    if (data && data.results.length >= data.pagination.count) {
      setHasMore(false)
      return
    }

    fetchPage()
  }

  useEffect(() => {
    fetchPage()
  }, [])

  function onChangeDateInput(name: string, value: string) {
    if (!name) {
      return
    }

    if (!date && !value) {
      return
    }

    // backspace
    if (value.length < date.length) {
      // delete points in mask
      if (value.length === 3 || value.length === 6) {
        setDate(value.slice(0, -1))
        return
      }

      setDate(value)
      return
    }

    // input
    const clearValue = value.replace(/\D/g, '')
    const formattedValue = `${clearValue.slice(0, 2)}.${clearValue.slice(
      2,
      4,
    )}.${clearValue.slice(4, 8)}`
    setDate(formattedValue)
  }

  const [isShow, setShow] = useState(false)

  const showAllEvents = () => {
    setCheckboxValues([])
  }

  const setupFilter = () => {
    toggleModal()
  }

  const toggleModal = () => {
    setShow(state => !state)
  }

  const getSelectedValues = (array: string[]) => {
    toggleModal()
    setCheckboxValues(array)
  }

  const navigateToEvent = (id: string) => {
    navigate(`/events/${id}`)
  }

  if (isLoading) return <LoaderFullScreen />
  if (isError) return <p>Возникла непредвиденная ошибка</p>

  return (
    <>
      <Header
        title='журнал событий пожарной сигнализации'
        subtitle='Для подробной информации выберите запись'
      />
      {!data?.results ? (
        <p>Нет ни одного события</p>
      ) : (
        <>
          <div className='table-wrapper'>
            <CustomInput
              value={date}
              name='number'
              type='date'
              title='Выберите дату'
              placeholder='укажите дату'
              onChange={onChangeDateInput}
              style={{ width: 236 }}
            />

            <InfiniteScroll
              dataLength={data.results.length}
              next={fetchNextPage}
              hasMore={hasMore}
              loader={<Loader />}
              height={500}
            >
              <Table
                events={data.results}
                total={data.pagination.count}
                setCurrentEvent={navigateToEvent}
              />
            </InfiniteScroll>
          </div>

          <ModalWithTitle
            isOpen={isShow}
            title='выберите категории событий'
            content={
              <FormCheckedStatus
                checkboxValues={checkboxValues}
                getSelectedValues={getSelectedValues}
              />
            }
            closeModal={toggleModal}
          />
        </>
      )}
    </>
  )
}

export default Events8Inch
