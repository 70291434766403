import { useState } from 'react'
import { SingleValue } from 'react-select'
import Select from 'react-select'
import Header from '../../components/Header'
import { errorsHandler } from '../../shared/globalMethods/errorsHander'
import Modal from 'react-modal'
import './SettingsNetwork.css'
import { NumPad } from '../../components/NumPad'
import { ISelectOption } from '../../types'
import {
  options,
  selectNetworkStyles,
} from '../../shared/dataComponents/selectSettingsNetwork'
import { modalNumPadStyles } from '../../shared/stylesJS/modalStyle'
import { showBackendNotifications } from '../../shared/globalMethods/showBackendNotifications'
import { $axios } from '../../shared/api'
import CustomInput from '@/components/CustomInput'

const SettingsNetwork = () => {
  const [selectedOption, setSelectedOption] =
    useState<SingleValue<ISelectOption | null>>(null)
  const [node, setNode] = useState('')
  const [isOpenModalInput, setIsOpenModalInput] = useState(false)

  const handleChangeSelect = (e: SingleValue<ISelectOption | null>) => {
    setSelectedOption(e)
  }

  const handleClickCheckModalNumpad = (name: string, value: string) => {
    setNode(state => (state = value))
    handleClickCloseModalNumpad()
  }

  const handleFocus = () => {
    setIsOpenModalInput(true)
  }

  const getParams = (type: string, newNode?: number) => {
    return type !== 'manual'
      ? {
          type,
        }
      : {
          type,
          node: newNode,
        }
  }

  const handleSaveConfigurate = () => {
    if (!selectedOption) {
      return errorsHandler('Вы не выбрали ни одного варианта')
    }

    if (selectedOption.value === 'manual' && !node) {
      return errorsHandler('Вы не указали узел')
    }

    $axios
      .post('/api/network', getParams(selectedOption.value, Number(node)))
      .then(() => {
        showBackendNotifications({
          title: 'Данные успешно сохранены',
          type: 'success',
        })
      })
      .catch(() => errorsHandler())
  }

  const handleClickCloseModalNumpad = () => {
    setIsOpenModalInput(false)
  }

  return (
    <>
      <Header
        title='Настройки сети'
        subtitle='настройте сеть под ваши потребности'
      />
      <div className='settings-network'>
        <p>Вариант настройки сети:</p>
        <Select
          value={selectedOption}
          onChange={handleChangeSelect}
          options={options}
          className='react-select-container'
          classNamePrefix='react-select'
          styles={selectNetworkStyles}
          placeholder='Выберите вариант настройки сети'
        />
      </div>
      {selectedOption?.value === 'manual' ? (
        <CustomInput
          name='node'
          title='Введите узел'
          value={node}
          onChange={handleClickCheckModalNumpad}
        />
      ) : null}

      <button
        className='primary-button settings-network__button'
        onClick={handleSaveConfigurate}
        disabled={!selectedOption}
      >
        Сохранить
      </button>
    </>
  )
}

export default SettingsNetwork
