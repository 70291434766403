import { IconProps } from '@/types'
import CheckNote from './CheckNote'
import Configurate from './Configurate'
import Diagram from './Diagram'
import DocAndCheckbox from './DocAndCheckbox'
import DocumentDefence from './DocumentDefence'
import Fire from './Fire'
import Help from './Help'
import Light from './Light'
import List from './List'
import Log from './Log'
import Malfunction from './Malfunction'
import MapPoint from './MapPoint'
import Reload from './Reload'
import Reset from './Reset'
import SandClocks from './SandClocks'
import SettingsInstruments from './SettingsInstruments'
import SixPoints from './SixPoints'
import Tile from './Tile'
import Unconnected from './Unconnected'
import Warning from './Warning'

type SidebarIconProps = IconProps & { name: string }

const SidebarIcon = ({ name, size = 64, color }: SidebarIconProps) => {
  const props = { size, color }

  switch (name) {
    case 'diagram': {
      return <Diagram {...props} />
    }
    case 'fire': {
      return <Fire {...props} />
    }
    case 'warning': {
      return <Warning {...props} />
    }
    case 'malfunction': {
      return <Malfunction {...props} />
    }
    case 'unconnected': {
      return <Unconnected {...props} />
    }
    case 'sixpoints': {
      return <SixPoints {...props} />
    }
    case 'check-note': {
      return <CheckNote {...props} />
    }
    case 'reset': {
      return <Reset {...props} />
    }
    case 'doc-and-checkbox': {
      return <DocAndCheckbox {...props} />
    }
    case 'document-defence' : {
      return <DocumentDefence {...props} />
    }
    case 'log': {
      return <Log {...props} />
    }
    case 'map-point': {
      return <MapPoint {...props} />
    }
    case 'light': {
      return <Light {...props} />
    }
    case 'settings-instruments': {
      return <SettingsInstruments {...props} />
    }
    case 'configurate': {
      return <Configurate {...props} />
    }
    case 'help': {
      return <Help {...props} />
    }
    case 'reload': {
      return <Reload {...props} />
    }
    case 'list': {
      return <List {...props} />
    }
    case 'tile': {
      return <Tile {...props} />
    }
    case 'sand-clocks': {
      return <SandClocks {...props} />
    }
    default: {
      return null
    }
  }
}

export default SidebarIcon
