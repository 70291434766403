import { IconProps } from '@/types'

const Configurate = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.47357 11.9894H7.04788C6.75495 11.2201 6.59318 10.3867 6.59318 9.51581C6.59318 8.64496 6.75503 7.81161 7.04788 7.04224H2.47357C1.10742 7.04224 0 8.14966 0 9.51581C0 10.882 1.10742 11.9894 2.47357 11.9894Z'
        fill={color}
      />
      <path
        d='M39.5264 11.9894C40.8926 11.9894 42 10.882 42 9.51581C42 8.14966 40.8926 7.04224 39.5264 7.04224H20.0867C20.3796 7.81153 20.5415 8.64496 20.5415 9.51581C20.5415 10.3867 20.3796 11.22 20.0867 11.9894H39.5264Z'
        fill={color}
      />
      <path
        d='M13.5672 14.029C16.0598 14.029 18.0805 12.0083 18.0805 9.51572C18.0805 7.0231 16.0598 5.00244 13.5672 5.00244C11.0746 5.00244 9.05396 7.0231 9.05396 9.51572C9.05396 12.0083 11.0746 14.029 13.5672 14.029Z'
        fill={color}
      />
      <path
        d='M39.5264 18.5266H33.2117C33.5046 19.2959 33.6665 20.1293 33.6665 21.0002C33.6665 21.871 33.5046 22.7044 33.2117 23.4738H39.5264C40.8926 23.4738 42 22.3663 42 21.0002C42 19.634 40.8926 18.5266 39.5264 18.5266Z'
        fill={color}
      />
      <path
        d='M2.47357 23.4738H20.1729C19.8799 22.7045 19.7182 21.871 19.7182 21.0002C19.7182 20.1293 19.88 19.296 20.1729 18.5266H2.47357C1.10742 18.5266 0 19.634 0 21.0002C0 22.3663 1.10742 23.4738 2.47357 23.4738Z'
        fill={color}
      />
      <path
        d='M26.6922 25.5134C29.1848 25.5134 31.2055 23.4927 31.2055 21.0001C31.2055 18.5075 29.1848 16.4868 26.6922 16.4868C24.1996 16.4868 22.179 18.5075 22.179 21.0001C22.179 23.4927 24.1996 25.5134 26.6922 25.5134Z'
        fill={color}
      />
      <path
        d='M39.5264 30.011H25.0085C25.3015 30.7803 25.4633 31.6137 25.4633 32.4846C25.4633 33.3554 25.3015 34.1888 25.0085 34.9581H39.5264C40.8926 34.9581 42 33.8507 42 32.4846C42 31.1184 40.8926 30.011 39.5264 30.011Z'
        fill={color}
      />
      <path
        d='M2.47357 30.011C1.10742 30.011 0 31.1184 0 32.4846C0 33.8507 1.10742 34.9581 2.47357 34.9581H11.9698C11.6768 34.1888 11.5151 33.3554 11.5151 32.4846C11.5151 31.6137 11.6769 30.7804 11.9698 30.011H2.47357Z'
        fill={color}
      />
      <path
        d='M18.4891 36.9977C20.9817 36.9977 23.0024 34.9771 23.0024 32.4845C23.0024 29.9919 20.9817 27.9712 18.4891 27.9712C15.9965 27.9712 13.9758 29.9919 13.9758 32.4845C13.9758 34.9771 15.9965 36.9977 18.4891 36.9977Z'
        fill={color}
      />
    </svg>
  )
}

export default Configurate
