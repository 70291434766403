import { memo, useState } from 'react'
import { DeviceMenu } from '../../../DeviceMenu'
import UniversalDeviceImage from '../UniversalDeviceImage'
import UniversalDeviceTopographyCard from '../UniversalDeviceTopographyCard'
import menu from '../../../../assets/icons/small-menu.svg'

import './UniversalDeviceInfo.css'
import useClickOutside from '../../../../shared/hooks/useClickOutside'
import ModalMap from '@/components/ModalMap'
import { deviceModels } from '@/shared/deviceModels'
import { getColorStatusDevice } from '@/shared/globalMethods/getColorToStatus'
import InfoBox from '@/components/InfoBox'
import StatusCard from '@/components/StatusCard'
import { TDevice } from '@/types'

/**
 *
 * @param string address
 * @param string type
 * @param string sn
 * @param string status
 * @param number id
 * @param number devices
 * @param number parent_id
 * @param number room_id
 *
 */

type UniversalDeviceInfoProps = TDevice & {}
const UniversalDeviceInfo = memo(({
  id,
  device_type,
  address,
  model,
  status,
  sn,
  name,
  zone,
}: UniversalDeviceInfoProps) => {
  const [isOpenModalMap, setOpenModalMap] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const menuRef = useClickOutside(() => closeMenu())

  const openMenu = () => {
    setShowMenu(true)
  }

  const closeMenu = () => {
    setShowMenu(false)
  }

  const openModalMap = () => {
    setOpenModalMap(true)
  }

  const closeModalMap = () => {
    setOpenModalMap(false)
  }
  
  return (
    <>
      <div className='universal-device-info'>
        <UniversalDeviceImage type={device_type} model={model} />
        <div className='universal-device-info__column'>
          <InfoBox title='Тип датчика' subtitle={model}  />
          <InfoBox title='Адрес в системе' subtitle={address}  />
          <InfoBox title='Серийный номер' subtitle={sn}  />
        </div>
        <div className='universal-device-info__column'>
          <InfoBox title={'Состояние устройства'} subtitle={<StatusCard status={status} style={{background: getColorStatusDevice(status)}}/>} />
          <InfoBox title={'Расположение'} subtitle={`${zone?.room_num ?? 'null'} ${zone?.room_name ?? 'null'}`} />
        </div>

        <img
          className='universal-device-info__menu'
          src={menu}
          onClick={openMenu}
          alt='|||'
        />
      </div>

      <DeviceMenu
        id={id}
        isOpen={showMenu}
        closeSmallMenu={closeMenu}
        showRoomInMap={openModalMap}
        reference={menuRef}
      />

      <ModalMap
        isOpen={isOpenModalMap}
        closeModal={closeModalMap}
      />
    </>
  )
})

export default UniversalDeviceInfo
