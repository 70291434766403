import Checkbox from '@/components/Checkbox'
import styles from './FormCheckedStatus.module.css'

const FormCheckedStatus = ({ checkboxValues, getSelectedValues }: any) => {
  const handleButtonClick = () => {
    const checkedInputs = document.querySelectorAll('input:checked')
    const checkedValues = Array.from(checkedInputs).map((cb: any) => cb.value)

    getSelectedValues(checkedValues)
  }

  return (
    <div className={styles.form}>
      <div className={styles.wrapperInputs}>
        <Checkbox
          id='d1'
          label='пожар'
          value='пожар'
          name='status'
          checked={checkboxValues.find((item: string) => item === 'пожар')}
        />
        <Checkbox
          id='d2'
          label='отключения'
          value='отключения'
          name='status'
          checked={checkboxValues.find((item: string) => item === 'отключения')}
        />
        <Checkbox
          id='d3'
          label='внимание'
          value='внимание'
          name='status'
          checked={checkboxValues.find((item: string) => item === 'внимание')}
        />
        <Checkbox
          id='d4'
          label='неисправность'
          value='неисправность'
          name='status'
          checked={checkboxValues.find((item: string) => item === 'неисправность')}
        />
      </div>
      <button className='secondary-button' onClick={handleButtonClick}>
        показать
      </button>
    </div>
  )
}

export default FormCheckedStatus
