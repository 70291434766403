import { ISelectOption } from '../../types'
import {
  GroupBase,
  ControlProps,
  CSSObjectWithLabel
} from 'react-select'

export const options: ISelectOption[] = [
  { value: 'auto', label: 'АВТО' },
  { value: 'first_node', label: 'Первый узел' },
  { value: 'manual', label: 'Вручную' },
]

export const selectNetworkStyles = {
  control: (
    baseStyles: CSSObjectWithLabel,
    state: ControlProps<ISelectOption, false, GroupBase<ISelectOption>>,
  ) => ({
    ...baseStyles,
    width: 400,
  }),
}

export const selectConfigurateStyles = {
  control: (
    baseStyles: CSSObjectWithLabel,
    state: ControlProps<ISelectOption, false, GroupBase<ISelectOption>>,
  ) => ({
    ...baseStyles,
    width: 400,
    marginBottom: 20
  }),
}
