import { IconProps } from '@/types'

const SandClocks = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M33.3375 35.7H31.9104V31.0572C31.912 29.4633 31.5184 27.8939 30.7649 26.4893C30.0113 25.0848 28.9212 23.889 27.5922 23.009L24.5604 21L27.5922 18.991C28.9212 18.111 30.0113 16.9152 30.7649 15.5107C31.5184 14.1061 31.912 12.5367 31.9104 10.9427V6.3H33.3375C33.8248 6.3 34.2922 6.10641 34.6368 5.76181C34.9814 5.41721 35.175 4.94984 35.175 4.4625C35.175 3.97516 34.9814 3.50779 34.6368 3.16319C34.2922 2.81859 33.8248 2.625 33.3375 2.625H8.8375C8.35016 2.625 7.88279 2.81859 7.53819 3.16319C7.19359 3.50779 7 3.97516 7 4.4625C7 4.94984 7.19359 5.41721 7.53819 5.76181C7.88279 6.10641 8.35016 6.3 8.8375 6.3H10.5586V10.9427C10.5559 12.5369 10.949 14.1067 11.7027 15.5115C12.4563 16.9162 13.547 18.1118 14.8767 18.991L17.9086 21L14.8767 23.009C13.5527 23.8917 12.468 25.0889 11.7198 26.4934C10.9716 27.8979 10.5832 29.4659 10.5893 31.0572V35.7H8.8375C8.35016 35.7 7.88279 35.8936 7.53819 36.2382C7.19359 36.5828 7 37.0502 7 37.5375C7 38.0248 7.19359 38.4922 7.53819 38.8368C7.88279 39.1814 8.35016 39.375 8.8375 39.375H33.3375C33.8248 39.375 34.2922 39.1814 34.6368 38.8368C34.9814 38.4922 35.175 38.0248 35.175 37.5375C35.175 37.0502 34.9814 36.5828 34.6368 36.2382C34.2922 35.8936 33.8248 35.7 33.3375 35.7ZM14.2336 31.0572C14.2326 30.0703 14.476 29.0985 14.9419 28.2286C15.4079 27.3586 16.082 26.6175 16.9041 26.0715L21.2345 23.205L25.5588 26.0715C26.3816 26.6172 27.0566 27.3581 27.5236 28.228C27.9907 29.0979 28.2352 30.0699 28.2354 31.0572V35.7H14.2336V31.0572Z'
        fill={color}
      />
    </svg>
  )
}

export default SandClocks
