import LoaderFullScreen from '@/components/AppHelpers/LoaderFullScreen'
import Header from '@/components/Header'
import { $axios } from '@/shared/api'
import { getColorStatusDevice } from '@/shared/globalMethods/getColorToStatus'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './Debugging.css'

const Debugging = () => {
  const [data, setData] = useState<any>(null)
  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(false)
  const reload = useSelector((state: any) => state.reload)

  useEffect(() => {
    const fecthData = async () => {
      try {
        const { data } = await $axios.get('/api/devices?type=ppku')
        setData(data)
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    fecthData()
  }, [reload])

  if (isLoading) return <LoaderFullScreen />
  if (isError) return <p>Возникла непредвиденная ошибка</p>
  if (!data) return <p>Данных нет</p>

  return (
    <>
      <Header title='отладка связи между узлами' />
      <div className='debugging-page__wrapper'>
        {data.results.map(
          ({
            id,
            address,
            status,
          }: {
            id: string
            address: string
            status: string
          }) => (
            <div className='debugging-page__ppku-card' key={id} style={{ background: getColorStatusDevice(status) }}>
              {address}
            </div>
          ),
        )}
      </div>
    </>
  )
}

export default Debugging
