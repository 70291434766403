import style from './MenuButton.module.css'
import Icon from '@/assets/icons/Icon'

type MenuButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  iconName: string
  iconSize?: number
  iconColor?: string
}

const MenuButton = ({
  iconName,
  iconSize = 42,
  iconColor = 'var(--main-color)',
  onClick,
  title,
}: MenuButtonProps) => {
  return (
    <button className={style.menuButton} onClick={onClick}>
      <Icon name={iconName} size={window.innerWidth < 1200 ? 24 : iconSize} color={iconColor} />
      {title}
    </button>
  )
}

export default MenuButton
