import styles from './RoomCard.module.css'
import eventIllustration from '../../../assets/icons/room-illustration1.svg'

const RoomCard = ({ name, responsible, status }: any) => {
  return (
    <div className={styles.roomCard}>
      <img src={eventIllustration} alt='' />
      <div className={styles.roomCard__titles}>
        <h2>{name}</h2>
        <div className={styles.roomCard__infoCards}>
          <div></div>
          <div className={styles.roomCard__rightInfo}>
            <div className={styles.roomCard__infoCard}>
              <p>Ответственный</p>
              <span>{responsible}</span>
            </div>

            <div className={styles.roomCard__infoCard}>
              <p>Расположение</p>
              <span>Главный корпус, 1 этаж, пом. 327/10</span>
            </div>

            <p>Статус комнаты:{' '} {status ?? 'статуса нет'}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export { RoomCard }
