import manual_udp from '@/assets/icons/types_devices/manual_udp.svg'
import thermal from '@/assets/icons/types_devices/thermal.svg'
import smoke from '@/assets/icons/types_devices/smoke.svg'
import comby from '@/assets/icons/types_devices/comby.svg'
import ppku from '@/assets/icons/types_devices/ppku.svg'
import kupt from '@/assets/icons/types_devices/kupt.svg'
import signal from '@/assets/icons/types_devices/signal.svg'
import light_sound from '@/assets/icons/types_devices/light_sound.svg'
import stub from '@/assets/icons/types_devices/stub.svg'

import { deviceModels } from '@/shared/deviceModels'

import './DeviceCardIcon.css'

const DeviceCardIcon = ({ model, style }: any) => {
  const getSrcIcon = (): string => {
    switch (model) {
      case deviceModels.ppku:
        return ppku

      case deviceModels.kupt:
        return kupt

      case deviceModels.comby:
        return comby

      case deviceModels.manual_udp:
        return manual_udp

      case deviceModels.thermal:
        return thermal

      case deviceModels.smoke:
        return smoke

      case deviceModels.signal:
        return signal

      case deviceModels.light_sound:
        return light_sound

      default:
        return stub
    }
  }

  return (
    <div className='device-card__img' style={style}>
      <img src={getSrcIcon()} alt={model ?? 'model-device'} />
    </div>
  )
}

export default DeviceCardIcon
