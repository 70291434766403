import { createSlice } from '@reduxjs/toolkit'

type TTimeRequest = {
  url: string
  duration: number
}

const initialState: TTimeRequest[] = []

const timeRequestsSlice = createSlice({
  name: 'timeRequests',
  initialState,
  reducers: {
    pushInTimeRequests(state, {payload}) {
      console.log('payload',payload)
      state.push({ url: payload.url, duration: payload.duration })
    },
  },
})

export const { pushInTimeRequests } = timeRequestsSlice.actions

export default timeRequestsSlice
