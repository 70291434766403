import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import LoaderFullScreen from '@/components/AppHelpers/LoaderFullScreen'
import ProgressBar from '@/components/Status/ProgressBar'
import GridEvents from '@/components/Status/GridEvents'
import Header from '@/components/Header'
import './Dashboard.css'

import { errorsHandler } from '@/shared/globalMethods/errorsHander'
import { $axios } from '@/shared/api'

const Dashboard = () => {
  const reload = useSelector((state: any) => state.reload)
  const [dashboardData, setDashboardData] = useState<any>(null)
  const [stateFetchData, setStateFetchData] = useState('load')

  const fetchDashboardData = async () => {
    try {
      const { data } = await $axios.get(`/api/dashboard`)
      setDashboardData(data)
      setStateFetchData('success')
    } catch (e: any) {
      errorsHandler(e)
      setStateFetchData('error')
    }
  }
  useEffect(() => {
    fetchDashboardData()
  }, [reload])

  if (stateFetchData === 'load') return <LoaderFullScreen />
  if (stateFetchData === 'error') return <p>Возникла внутренняя ошибка сервера</p>

  return (
    <>
      <Header
        backPath='none'
        title='список событий'
        subtitle='Кристалл-200AP'
      />
      {dashboardData && (
        <div className='status-page__progress-bars'>
          <ProgressBar
            to={'/topology'}
            type='devices'
            data={dashboardData.devices}
          />
          <ProgressBar
            to={'/all/rooms'}
            type='security'
            data={dashboardData.zones}
          />
          <ProgressBar
            to={'/events?page=1'}
            type='events'
            data={dashboardData.events}
          />
        </div>
      )}

      <GridEvents />
    </>
  )
}

export default Dashboard
