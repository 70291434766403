import { DeviceCard } from '@/components/DeviceCard'
import StatusCard from '@/components/StatusCard'
import { getColorStatusZones } from '@/shared/globalMethods/getColorToStatus'
import { ActiveEventCardProps } from '@/types'
import { Link } from 'react-router-dom'
import styles from './ActiveEventCard9Inch.module.css'

const ActiveEventCard9Inch = ({
    id,
    zone_num,
    status,
    devices,
    room_num,
    room_name
  }: ActiveEventCardProps) => {
    const title = `${room_num} ${room_name}`

    return (
      <div className={styles.card}>
        <Link
          to={`/all/zones/${id}`}
          className={`${styles.card_indicator}`}
          style={{ background: getColorStatusZones(status) }}
        >
          {zone_num}
        </Link>
        <div className={styles.card_infobox}>
          <span>{title}</span>
          <div className={styles.card_infobox_bottom}>
            <div className={styles.card_devices}>
              {devices?.map(item => (
                <DeviceCard
                  key={item.id}
                  hardStatus={status === 'пожар1' ? 'пожар1' : ''}
                  {...item}
                />
              ))}
            </div>
            <div className={styles.text_box}>
              <p>Состояние пожаротушения</p>
              <StatusCard
                status={status}
                style={{ background: getColorStatusZones(status) }}
              />
            </div>
          </div>
        </div>
      </div>
    )
}

export default ActiveEventCard9Inch