import ppku from '@/assets/images/ppku.png'
import kupt from '@/assets/images/kupt.png'
import sensor from '@/assets/images/sensor.png'
import manual_udp from '@/assets/images/manual_udp.png'
import signal from '@/assets/images/signal.png'
import light_sound from '@/assets/images/light_sound.png'
import './UniversalDeviceImage.css'
import { deviceModels } from '@/shared/deviceModels'

type UniversalDeviceImageProps = {
  type: string
  model: string
}

const UniversalDeviceImage = ({ type, model }: UniversalDeviceImageProps) => {
  const getSrcImage = (): string => {
    switch (type) {
      case 'ppku':
        return ppku
      case 'kupt':
        return kupt
      case 'device': {
        if (model === deviceModels.manual_udp) return manual_udp
        if (model === deviceModels.signal) return signal
        if (model === deviceModels.light_sound) return light_sound
        return sensor
      }

      default:
        return sensor
    }
  }

  return (
    <div className='universal-device-image'>
      <img src={getSrcImage()} alt={model ?? 'device-img'} />
    </div>
  )
}

export default UniversalDeviceImage
