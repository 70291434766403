import styles from './EventCard.module.css'
import eventIllustration from '@/assets/icons/event-illustration1.svg'
import { EventItem } from '@/types'

const EventCard = ({ event_text, id }: EventItem) => {
  return (
    <div className={styles.eventCard}>
      <img src={eventIllustration} alt='' />
      <div className={styles.eventCard__titles}>
        <h2>{event_text}</h2>
        <div className={styles.eventCard__infoCards}>
          <div className={styles.eventCard__infoCard}>
            <p>Адрес</p>
            <span>01.05.143</span>
          </div>

          <div className={styles.eventCard__infoCard}>
            <p>ППКПУ</p>
            <span>01</span>
          </div>

          <div className={styles.eventCard__infoCard}>
            <p>Шлейф</p>
            <span>05</span>
          </div>

          <div className={styles.eventCard__infoCard}>
            <p>Номер устройства</p>
            <span>{id}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export { EventCard }
