import { useNavigate } from 'react-router-dom'
import CellEvent from '../CellEvent'
import styles from './GridEvents.module.css'

const GridEvents = () => {
  // const { isLoading, isError } = useSelector((state: any) => state.eventsCount)
  const navigate = useNavigate()

  const navigateToAlarm = (count: number) => {
    if (!count) return

    navigate(`/all/zones?status=огонь`)
  }

  return (
    <div className={styles.grid}>
      <CellEvent count={1} label={'АУПТ'} />
      <CellEvent count={1} label={'СВДУ'} />
      <CellEvent count={1} label={'СОУЭ'} />
      <CellEvent count={1} label={'ЗКПС'} />

      <CellEvent count={0} label={'связь'} />
      <CellEvent count={0} label={'питание'} />
      <CellEvent count={0} label={'вскрытие'} />
      <CellEvent count={1} label={'дежурный'} />
    </div>
  )
}

export default GridEvents
