import { EventStatuses } from '@/enums'
import { MouseEventHandler } from 'react'
import { Link } from 'react-router-dom'
import styles from './TableEventsTools.module.css'

type TTableEventsTools = {
  showAllEvents: any
  setupFilter: any
  currentEvent: string
  countCurrentStatuses?: number
}

const TableEventsTools = ({
  showAllEvents,
  setupFilter,
  currentEvent,
  countCurrentStatuses
}: TTableEventsTools) => {
  const getClassName = (isChecked: boolean) => {
    return isChecked ? 'primary-button' : 'secondary-button'
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.categories}>
        <button
          id={EventStatuses['critical']}
          className={getClassName(countCurrentStatuses == 0)}
          onClick={showAllEvents}
        >
          все события
        </button>
        <button
          id={EventStatuses['special']}
          className={getClassName(countCurrentStatuses !== 0)}
          onClick={setupFilter}
        >
          настроить фильтр ({countCurrentStatuses})
        </button>
      
      </div>

      {currentEvent ? (
        <Link to={`/events/${currentEvent}`} className='secondary-button'>
          подробнее
        </Link>
      ) : (
        <></>
      )}
    </div>
  )
}

export default TableEventsTools
