import { getColorStatusRoom } from '@/shared/globalMethods/getColorToStatus'
import { Link } from 'react-router-dom'
import type { TZoneItem } from '@/types'
import './RoomCard.css'

type RoomCardProps = TZoneItem & {
  type: string
}

const RoomCard = ({
  id,
  room_name,
  room_num,
  status,
  zone_num,
  type
}: RoomCardProps) => {
  return (
    <Link
      to={`/all/zones/${id}`}
      className='room-card'
      style={{ borderColor: getColorStatusRoom(status) }}
    >
      <div
        className='room-card__status'
        style={{ background: getColorStatusRoom(status) }}
      >
       <p className='room-card__status-title'>{zone_num ?? 'Null'}</p> 
       <p className='room-card__status-subtitle'>{type}</p>
        
      </div>
      <div className='room-card__info'>
        <h3 style={{fontWeight: 700}}>{room_num ?? 'Null'}</h3>
        <h3>{room_name ?? 'Отсутсвует название'}</h3>
      </div>
    </Link>
  )
}
export default RoomCard
