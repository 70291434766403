import { getColorStatusZones } from '@/shared/globalMethods/getColorToStatus'
import { Link } from 'react-router-dom'
import type { TZoneItem } from '@/types'
import styles from './ZoneCard.module.css'

type ZoneCardProps = TZoneItem & {}

const ZoneCard = ({ id, zone_num, status }: ZoneCardProps) => {
  return (
    <Link
      to={`/all/zones/${id}`}
      className={styles.card}
      style={{ background: getColorStatusZones(status) }}
    >
      {zone_num}
    </Link>
  )
}
export { ZoneCard }
