/**
 * 
 * сортировка по статусу: 
 *  "ПОЖАР" (наивысший приоритет)
 *  "СБОЙ" (второй по величине)
 *  "ОТКЛ" (третий по величине)
 *  "НОРМА" (наименьший приоритет)
 * 
 */
export const sortByStatus = (a: any, b: any) => {
  return a.status > b.status ? -1 : 1
}
