import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import API_BASE_URL from '../apiBaseURL'

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
  }),
  endpoints: build => ({
    fetchEvents: build.query({
      query: () => `/api/events`,
    }),
    fetchEventOnPage: build.query({
      query: ({ page, perPage }: any) =>
        `/api/events?page=${page}&per-page=${perPage}`,
    }),
    fetchEventId: build.query({
      query: (id: string) => `/api/events${id ? `/${id}` : ''}`,
    }),

    // http://leader-front.mooo.com:23337/api/events?page=1&per-page=5
  }),
})
