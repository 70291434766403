export const deviceModels = {
  ppku: 'ппкпу',
  kupt: 'купт',
  thermal: 'тепловой извещатель',
  smoke: 'дымовой извещатель',
  manual_udp: 'ручной извещатель',
  comby: 'комбинированный извещатель',
  signal: 'пожарный оповещатель ',
  light_sound: 'свето-звуковой оповещатель'
}
