import { errorsHandler } from '@/shared/globalMethods/errorsHander'
import { SeviceCommandsEnum } from '@/shared/enums/service'
import { $axios } from '@/shared/api'
import { TService } from '@/types'

import styles from './Service.module.css'

const Service = ({ name, active, autostart }: TService) => {
  const sendData = (command: SeviceCommandsEnum) => {
    $axios
      .post('/api/info', {
        name,
        command: SeviceCommandsEnum[command],
      })
      .then(data => console.log(data))
      .catch(error => errorsHandler(error.responce.data.message))
  }

  return (
    <div className={styles.card}>
      <p>{name}</p>
      <div className={styles.buttons}>
        {active ? (
          <button onClick={() => sendData(SeviceCommandsEnum.stop)} className={styles.buttonStop}>
            Остановить
          </button>
        ) : (
          <button onClick={() => sendData(SeviceCommandsEnum.run)} className={styles.buttonStart}>
            Запустить
          </button>
        )}
        <button onClick={() => sendData(SeviceCommandsEnum.restart)} className={styles.buttonReload}>
          Перезагрузка
        </button>
      </div>
    </div>
  )
}

export default Service
