import { IconProps } from '@/types'
import React from 'react'

const Warning = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M57.2095 43.751L38.6644 11.6294C35.6353 6.32297 27.9753 6.32297 24.9571 11.6294L6.40105 43.751C3.37194 49.0574 7.14198 55.6496 13.2547 55.6496H50.2905C56.4032 55.6496 60.2386 48.9921 57.2095 43.751ZM31.7998 48.5235C30.1872 48.5235 28.847 47.1833 28.847 45.5707C28.847 43.9581 30.1872 42.6178 31.7998 42.6178C33.4125 42.6178 34.7527 43.9581 34.6873 45.647C34.7636 47.1833 33.3471 48.5235 31.7998 48.5235ZM34.4912 29.4336C34.3604 31.7218 34.2188 33.999 34.088 36.2872C34.0226 37.0281 34.0226 37.7037 34.0226 38.4337C33.9573 39.6432 33.0093 40.5803 31.7998 40.5803C30.5904 40.5803 29.6533 39.7086 29.577 38.4991C29.3809 34.9361 29.1739 31.4385 28.9777 27.8754C28.9124 26.9384 28.847 25.9904 28.7707 25.0533C28.7707 23.5061 29.6424 22.2313 31.0589 21.8281C32.4754 21.4903 33.881 22.1659 34.4912 23.5061C34.6982 23.9746 34.7636 24.4432 34.7636 24.988C34.6982 26.4807 34.5565 27.9626 34.4912 29.4336Z'
        fill={color}
      />
    </svg>
  )
}

export default Warning
