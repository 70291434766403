import StatusCard from '@/components/StatusCard'
import { getColorCategoryEvent } from '@/shared/globalMethods/getColorToStatus'
import { EventItem } from '@/types'
import styles from './TopInfoBar.module.css'

type TopInfoBarProps = EventItem & {}

const TopInfoBar = ({
  created_at,
  category,
  confirmed_by,
}: TopInfoBarProps) => {
  return (
    <div className={styles.topInfoBar}>
      <div className={styles.topInfoBar__card}>
        <p className={styles.topInfoBar__title}>Момент фиксации события</p>
        <div className={styles.topInfoBar__row}>
          <h3>{created_at}</h3>
        </div>
      </div>

      <div className={styles.topInfoBar__card}>
        <p className={styles.topInfoBar__title}>Категория события</p>
        <div className={styles.topInfoBar__statusesBox}>
          <StatusCard
            status={category}
            style={{ background: getColorCategoryEvent(category) }}
          />
          {confirmed_by ? (
            <StatusCard
              status='квитировано'
              style={{ background: getColorCategoryEvent('квитировано') }}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export { TopInfoBar }
