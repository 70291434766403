import { TSidebarItem } from '@/types'

export const sidebarItems: TSidebarItem[] = [
  {
    name: 'статус',
    path: { pathname: '/dashboard' },
    title: 'статус',
    primaryColor: 'var(--main-color)',
    secoundaryColor: 'var(--secondary-color)',
    disabledColor: 'var(--disabled-color)',
    iconName: 'diagram',
  },

  {
    name: 'пожар2',
    path: {
      pathname: '/active-events',
      search: 'status=ПОЖАР2',
    },
    title: 'Пожар 2',
    primaryColor: 'var(--fire2-color)',
    secoundaryColor: 'var(--secondary-color)',
    disabledColor: 'var(--disabled-color)',
    iconName: 'fire',
  },
  {
    name: 'пожар1',
    path: {
      pathname: '/active-events',
      search: 'status=ПОЖАР1',
    },
    title: 'Пожар 1',
    primaryColor: 'var(--fire1-color)',
    secoundaryColor: 'var(--secondary-color)',
    disabledColor: 'var(--disabled-color)',
    iconName: 'warning',
  },
  {
    name: 'неисправн',
    path: {
      pathname: '/active-events',
      search: 'status=НЕИСПРАВНОСТЬ',
    },
    title: 'неисправн.',
    primaryColor: 'var(--malfunction-color)',
    secoundaryColor: 'var(--secondary-color)',
    disabledColor: 'var(--disabled-color)',
    iconName: 'malfunction',
  },
  {
    name: 'внимание',
    path: {
      pathname: '/active-events',
      search: 'status=ВНИМАНИЕ',
    },
    title: 'внимание',
    primaryColor: 'var(--main-color)',
    secoundaryColor: 'var(--secondary-color)',
    disabledColor: 'var(--disabled-color)',
    iconName: 'warning',
  },

 
  {
    name: 'отключения',
    path: {
      pathname: '/all/rooms',
      search: 'status=ОТКЛ',
    },
    title: 'отключения',
    primaryColor: 'var(--unconnection-color)',
    secoundaryColor: 'var(--secondary-color)',
    disabledColor: 'var(--disabled-color)',
    iconName: 'unconnected',
  },
  
  // {
  //   name: 'все',
  //   path: { pathname: '/all' },
  //   title: 'показать все',
  //   primaryColor: 'var(--main-color)',
  //   secoundaryColor: 'var(--secondary-color)',
  //   disabledColor: 'var(--disabled-color)',
  //   iconName: 'sixpoints',
  // },
]
