import { IconProps } from '@/types'

const SettingsInstruments = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24.3468 2.84804C24.1555 2.71857 23.9334 2.64189 23.7029 2.62576C23.4724 2.60963 23.2418 2.65461 23.0343 2.75616C21.7008 3.34501 20.5569 4.29245 19.7299 5.49298C18.903 6.69351 18.4255 8.10001 18.3507 9.55585C18.2759 11.0117 18.6066 12.4597 19.3061 13.7387C20.0056 15.0177 21.0463 16.0774 22.3124 16.7999V35.4374C22.3124 36.4817 22.7273 37.4832 23.4657 38.2216C24.2041 38.9601 25.2056 39.3749 26.2499 39.3749C27.2942 39.3749 28.2957 38.9601 29.0342 38.2216C29.7726 37.4832 30.1874 36.4817 30.1874 35.4374V16.7999C31.4516 16.073 32.4891 15.0093 33.1843 13.7273C33.8795 12.4454 34.2051 10.9957 34.1248 9.53958C34.0445 8.08351 33.5615 6.67832 32.7296 5.48061C31.8977 4.2829 30.7495 3.33972 29.4131 2.75616C29.2056 2.65461 28.9749 2.60963 28.7445 2.62576C28.514 2.64189 28.2919 2.71857 28.1006 2.84804C27.9297 2.97281 27.7915 3.13703 27.6979 3.32671C27.6042 3.51639 27.5577 3.72591 27.5624 3.93741V8.66241H24.9374V3.93741C24.9363 3.72124 24.8818 3.50868 24.7788 3.31864C24.6757 3.12859 24.5274 2.96694 24.3468 2.84804Z'
        fill={color}
      />
      <path
        d='M13.7812 2.625C13.3492 2.625 12.9215 2.7103 12.5225 2.87602C12.1236 3.04174 11.7613 3.28461 11.4564 3.59069C11.1515 3.89678 10.9101 4.26005 10.746 4.65966C10.5819 5.05928 10.4983 5.48737 10.5 5.91938V22.5619C9.73619 22.8319 9.07427 23.331 8.6045 23.991C8.13473 24.651 7.87998 25.4399 7.875 26.25V35.4375C7.875 36.4818 8.28984 37.4833 9.02827 38.2217C9.76669 38.9602 10.7682 39.375 11.8125 39.375H15.75C16.7943 39.375 17.7958 38.9602 18.5342 38.2217C19.2727 37.4833 19.6875 36.4818 19.6875 35.4375V26.25C19.6852 25.4376 19.4317 24.6459 18.9618 23.9832C18.4918 23.3206 17.8284 22.8195 17.0625 22.5487V5.91938C17.0642 5.48737 16.9806 5.05928 16.8165 4.65966C16.6524 4.26005 16.411 3.89678 16.1061 3.59069C15.8012 3.28461 15.4389 3.04174 15.04 2.87602C14.641 2.7103 14.2133 2.625 13.7812 2.625ZM13.125 5.91938C13.125 5.74533 13.1941 5.57841 13.3172 5.45534C13.4403 5.33227 13.6072 5.26313 13.7812 5.26313C13.9553 5.26313 14.1222 5.33227 14.2453 5.45534C14.3684 5.57841 14.4375 5.74533 14.4375 5.91938V22.3125H13.125V5.91938ZM10.5 26.25C10.5 25.9019 10.6383 25.5681 10.8844 25.3219C11.1306 25.0758 11.4644 24.9375 11.8125 24.9375H15.75C16.0981 24.9375 16.4319 25.0758 16.6781 25.3219C16.9242 25.5681 17.0625 25.9019 17.0625 26.25H10.5Z'
        fill={color}
      />
    </svg>
  )
}

export default SettingsInstruments
