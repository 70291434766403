import ActiveEventCard from '@/components/ActiveEventCard'
import Header from '@/components/Header'
import LoaderFullScreen from '@/components/AppHelpers/LoaderFullScreen'
import { useQuery } from '@/shared/hooks/useQuery'
import { $axios } from '@/shared/api'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './ActiveEvents.css'

const ActiveEvents = () => {
  const reload = useSelector((state: any) => state.reload)
  const query = useQuery()
  const status = query.get('status')

  const [data, setData] = useState<any>()
  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(false)

  const fetchData = async () => {
    try {
      const response = await $axios.get(`/api/active-events?status=${status}`)
      setData(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(true)
    }
  }

  useEffect(() => {
    fetchData()
  }, [status, reload])

  if (isLoading) return <LoaderFullScreen />

  if (isError) {
    return <p>Возникла внутренняя ошибка сервера</p>
  }

  return (
    <div>
      <Header
        title='активные события'
        subtitle='для подробной информации выберите помещение'
      />

      <div className='active-events___list'>
        {data.results.length ? (
          data.results.map((item: any) => (
            <ActiveEventCard key={item.id} {...item} />
          ))
        ) : (
          <p>Активных событий нет</p>
        )}
      </div>
    </div>
  )
}

export default ActiveEvents
