import MenuButton from '@/components/Buttons/MenuButton'
import { $axios } from '@/shared/api'
import { errorsHandler } from '@/shared/globalMethods/errorsHander'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-modal'
import { modalInfoStyles } from '@/shared/stylesJS/modalStyle'
import ServicesList from '@/components/Header/components/ServicesList'
import GenerateTestEvent from '@/components/Header/components/GenerateTestEvent'
import { ObjectToArray } from '@/shared/helpers'
import Header from '@/components/Header'

type TModalInfo = {
  state: boolean
  data: any | null
  services: any | null
}

const Settings = () => {
  const navigate = useNavigate()
  const [isOpenModal, setIsOpenModal] = useState<TModalInfo>({
    state: false,
    data: null,
    services: null,
  })

  const closeModal = () => {
    setIsOpenModal({ state: false, data: null, services: null })
  }

  const getApiInfo = () => {
    $axios
      .get(`/api/info`)
      .then(({ data }) => {
        const newArrayServices = ObjectToArray(data.services, 'name')

        setIsOpenModal({
          state: true,
          data: data.info,
          services: newArrayServices,
        })
      })
      .catch(error => errorsHandler(error))
  }

  const testIndication = () => {
    return $axios.post('/api/test-indication')
  }

  const handleClickUpdate = () => {
    $axios
      .post(`/api/update`)
      .then(data => {
        console.log(data)
      })
      .catch(error => errorsHandler(`${error.request.status} ${error.message}`))
  }

  return (
    <>
      <Header title='Настройки' />
      <div>
        <MenuButton
          iconName='check-note'
          title='Диагностика'
          onClick={getApiInfo}
        />

        <MenuButton
          iconName='check-note'
          title='тест индикации'
          onClick={testIndication}
        />

        <MenuButton
          iconName='settings-instruments'
          title='Настройки сети'
          onClick={() => navigate('/settings-network')}
        />

        <MenuButton
          iconName='configurate'
          title='Конфигурация'
          onClick={() => navigate('/app-configurate')}
        />

        <MenuButton
          iconName='reload'
          title='Обновление ПО'
          onClick={handleClickUpdate}
        />

        <MenuButton
          iconName='document-defence'
          title='Отладка'
          onClick={() => navigate('/debugging')}
        />

        <Modal
          isOpen={isOpenModal.state}
          onRequestClose={closeModal}
          style={modalInfoStyles}
        >
          <div className='modal-info'>
            <pre>{JSON.stringify(isOpenModal.data, null, 2)}</pre>

            <ServicesList services={isOpenModal.services} />
            <GenerateTestEvent />
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Settings
