import { all } from 'redux-saga/effects'
import mqttSaga from './mqttSaga'
import reloadSaga from './reloadSaga';

function* rootSaga() {
  yield all([
    mqttSaga(),
    reloadSaga(),
  ])
}

export default rootSaga
