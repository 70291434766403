import Header from '../../components/Header'
import './TopologyAll.css'

const TopologyAll = () => {
  return (
    <>
      <Header
        title='адресные устройства на шлейфе'
        backPath='/topology'
        subtitle='для детального просмотра выберите шлейф и устройство'
      />
      <h2>В разработке 🚧</h2>
    </>
  )
  // const params = useParams()

  // // тестовый mash
  // // const [mash] = useState({
  // //   address: null,
  // //   id: 6,
  // //   device_type: 'mash',
  // //   devices: 9,
  // //   name: null,
  // //   parent_id: 5,
  // //   room_id: null,
  // //   sn: '456',
  // //   status: null,
  // // })

  // const mash = useMemo<any>(() => {
  //   const allDevices = data?.devices

  //   if (!allDevices) return null

  //   return allDevices[Number(params.mash_id)]
  // }, [data])

  // const devices = useMemo<any>(() => {
  //   const devices = data?.devices
  //   if (!devices) return null

  //   const findDevices = Object.values(devices).filter(
  //     (item: any) => item.parent_id === Number(params.mash_id),
  //   )
  //   return findDevices || null
  // }, [data, params.mash_id])

  // const trains = useMemo(() => {
  //   const allDevices = data?.devices
  //   if (!allDevices) return null

  //   const filterMashDevices = Object.values(allDevices).filter(
  //     (item: any) => item.parent_id === mash.id,
  //   )

  //   return filterMashDevices || null
  // }, [data, mash])

  // const [currentPage, setCurrentPage] = useState(1)
  // const [windowHeight] = useState(window.innerHeight)
  // const [devicesPerPage] = useState(
  //   windowHeight > 1079 ? 30 : windowHeight < 920 ? 16 : 24,
  // )
  // const lastDeviceIndex = currentPage * devicesPerPage
  // const firstDeviceIndex = lastDeviceIndex - devicesPerPage
  // const currentDevice = (
  //   devices !== null && devices !== undefined ? devices : []
  // )?.slice(firstDeviceIndex, lastDeviceIndex)

  // const prevPage = useCallback(
  //   () => setCurrentPage((prev: any) => prev - 1),
  //   [],
  // )
  // const nextPage = useCallback(
  //   () => setCurrentPage((prev: any) => prev + 1),
  //   [],
  // )

  // const getTotalPage = () => {
  //   if (devices !== null && devices !== undefined) {
  //     return Math.ceil(devices.length / devicesPerPage)
  //   }
  //   return 0
  // }

  // const getViewedElements = () => {
  //   if (devices !== null && devices !== undefined) {
  //     let newValue = devices.length - currentPage * devicesPerPage

  //     if (newValue < 0) {
  //       return devicesPerPage + newValue
  //     }

  //     return devices.length - newValue
  //   }

  //   return 0
  // }

  // useEffect(() => {
  //   setCurrentPage(1)
  // }, [window.location.href])

  // return (
  //   <div>
  // <Header
  //   title='адресные устройства на шлейфе'
  //   backPath='/topology'
  //   subtitle='для детального просмотра выберите шлейф и устройство'
  // />
  //     {trains !== null && (
  //       <div className='tabs'>
  //         <div className='tab-list'>
  //           {trains.map((item: any, index: number) => {
  //             return (
  //               <NavLink
  //                 key={item.id}
  //                 className='tab'
  //                 to={`/topology/devices-all/${item.id}`}
  //               >
  //                 {index + 1}
  //               </NavLink>
  //             )
  //           })}
  //         </div>
  //         <div className='tab-content room-grid '>
  //           {currentDevice !== null && currentDevice.length !== 0 ? (
  //             currentDevice.map((item: any) => {
  //               return <DeviceCard key={item.id} {...item} />
  //             })
  //           ) : (
  //             <p>Устройств нет</p>
  //           )}
  //         </div>
  //       </div>
  //     )}
  //     {devices !== null && devices !== undefined && (
  //       <Paginate
  //         perPage={devicesPerPage}
  //         totalPage={getTotalPage()}
  //         currentPage={currentPage}
  //         viewedElements={getViewedElements()}
  //         totalElements={devices.length}
  //         prevPage={prevPage}
  //         nextPage={nextPage}
  //       />
  //     )}
  //   </div>
  // )
}

export { TopologyAll }
