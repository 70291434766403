import { IconProps } from '@/types'

const Diagram = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M50.1139 16.8975L40.9929 26.0185C42.1264 27.5349 42.8532 29.3159 43.1045 31.1924C43.2848 31.1408 43.4706 31.1109 43.658 31.1033H55.6775C55.786 31.1131 55.8936 31.1305 55.9997 31.1552C55.6672 25.8828 53.5974 20.8691 50.1139 16.8975Z'
        fill={color}
      />
      <path
        d='M8.00002 32.7061C8.00518 38.8089 10.3321 44.6812 14.5086 49.1311C18.6851 53.5811 24.3982 56.2753 30.4884 56.667C30.4638 56.5608 30.4464 56.4531 30.4365 56.3445V44.325C30.4443 44.1376 30.4742 43.9518 30.5256 43.7714C28.8268 43.5491 27.2017 42.9396 25.7757 41.9899C24.3497 41.0403 23.1608 39.7758 22.3007 38.2941C21.4406 36.8123 20.9323 35.1528 20.8149 33.4436C20.6975 31.7343 20.9743 30.0209 21.6237 28.4355C22.2732 26.85 23.2781 25.4349 24.5609 24.2993C25.8437 23.1636 27.3702 22.3377 29.0226 21.8852C30.6751 21.4327 32.4094 21.3657 34.0918 21.6895C35.7743 22.0132 37.3599 22.7189 38.7265 23.7523L47.8475 14.6313C44.3765 11.585 40.1006 9.60433 35.5324 8.9268C30.9641 8.24927 26.2974 8.90359 22.0917 10.8113C17.8859 12.719 14.3196 15.7992 11.8202 19.6826C9.3208 23.566 7.99441 28.0879 8.00002 32.7061Z'
        fill={color}
      />
      <path
        d='M55.6775 34.3088H43.6579C43.4705 34.3012 43.2847 34.2713 43.1044 34.2197C42.7735 36.6363 41.6607 38.8781 39.936 40.6029C38.2113 42.3276 35.9694 43.4404 33.5529 43.7713C33.6042 43.9517 33.634 44.1376 33.6416 44.3251V56.3447C33.6317 56.4532 33.6144 56.5609 33.5897 56.6671C39.4094 56.2898 44.8928 53.8076 49.0165 49.6838C53.1403 45.5601 55.6224 40.0765 55.9996 34.2569C55.8936 34.2816 55.7859 34.299 55.6775 34.3088Z'
        fill={color}
      />
    </svg>
  )
}

export default Diagram
