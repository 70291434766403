import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { $axios } from '@/shared/api'
import { TZone } from '@/types'
import UniversalRoom from '@/components/UniversalRoom'
import './Zone.css'
import { useSelector } from 'react-redux'
import LoaderFullScreen from '@/components/AppHelpers/LoaderFullScreen'

const Zone = () => {
  const reload = useSelector((state: any) => state.reload)
  const { zone_id } = useParams()
  const [data, setData] = useState<TZone | null>(null)
  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(false)

  const fetchZoneId = async () => {
    try {
      const { data } = await $axios.get(`/api/zones/${zone_id}`)
      setData(data)
      setLoading(false)
    } catch (e) {
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchZoneId()
  }, [reload])

  if (isLoading) return <LoaderFullScreen />

  if (isError) return <p>Возникла ошибка</p>

  if (!data) return <p>Список зон пуст</p>

  return <UniversalRoom type='zone' {...data} />
}

export { Zone }
