import { combineReducers, configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import { errosHandlerMiddleware } from './middlewares/errorsHandlerMiddleware'
import mqttSlice from './reducers/mqttSlice'
import rootSaga from './sagas/rootSaga'

import { deviceChartApi } from './services/deviceChartApi'
import { topologyApi } from './services/topologyApi'
import { eventsApi } from './services/eventsApi'
import { zonesApi } from './services/zonesApi'

import reloadSlice from './reducers/reloadSlice'
import commonInputModal from './reducers/commonInputModalSlice'
import timeRequestsSlice from './reducers/timeRequestsSlice'

const rootReducer = combineReducers({
  [deviceChartApi.reducerPath]: deviceChartApi.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
  [topologyApi.reducerPath]: topologyApi.reducer,
  [mqttSlice.name]: mqttSlice.reducer,
  [reloadSlice.name]: reloadSlice.reducer,
  [timeRequestsSlice.name]: timeRequestsSlice.reducer,
  [commonInputModal.name]: commonInputModal.reducer,
})

const sagaMiddleware = createSagaMiddleware()

const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware()
        .concat(zonesApi.middleware)
        .concat(deviceChartApi.middleware)
        .concat(eventsApi.middleware)
        .concat(topologyApi.middleware)
        .concat(errosHandlerMiddleware)
        .concat(sagaMiddleware),
  })
}

export const store = setupStore()
export type TRootState = ReturnType<typeof rootReducer>
export type TAppStore = ReturnType<typeof setupStore>
export type TAppDispatch = TAppStore['dispatch']

sagaMiddleware.run(rootSaga)
