import styles from './Checkbox.module.css'

type RadioButtonProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string
  boxLabel?: string
}

const Checkbox = ({
  id,
  name,
  value,
  label,
  checked,
  boxLabel,
}: RadioButtonProps) => {
  return (
    <div className={styles.wrapper}>
      {boxLabel && <span>{boxLabel}</span>}
      <input
        className={styles.input}
        id={id}
        name={name}
        value={value}
        type='checkbox'
        defaultChecked={checked}
      />
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

export default Checkbox
