import { Loader } from '../Loader/Loader'
import styles from './LoaderFullScreen.module.css'

const LoaderFullScreen = () => {
  return (
    <div className={styles.wrapper}>
      <Loader />
    </div>
  )
}

export default LoaderFullScreen
