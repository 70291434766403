import { pushInTimeRequests } from '@/store/reducers/timeRequestsSlice'
import axios from 'axios'
import API_BASE_URL from '../../store/apiBaseURL'

export const $axios = axios.create({
  baseURL: API_BASE_URL,
})

if (process.env.REACT_APP_SHOW_TIME_REQUESTS === 'true') {
  $axios.interceptors.request.use(
    (config: any) => {
      config.metadata = { startTime: performance.now() }
      return config
    },
    error => {
      return Promise.reject(error)
    },
  )

  $axios.interceptors.response.use(
    (response: any) => {
      const duration = Math.round(
        performance.now() - response.config.metadata.startTime,
      )

      console.log(`${response.config.url} => ${duration} ms`)

      // Учет времени только фактического выполнения запроса (без подготовки)
      const serverExecutionTime = response.headers['x-server-execution-time']
      if (serverExecutionTime) {
        console.log(`ТОЧНОЕ => ${serverExecutionTime} ms`)
      }

      pushInTimeRequests({ url: response.config.url, duration: duration })

      return response
    },
    (error: any) => {
      const duration = Math.round(
        performance.now() - error.config.metadata.startTime,
      )
      console.log(
        `Request to ${error.config.url} failed and took ${duration} ms`,
      )
      pushInTimeRequests({ url: error.config.url, duration: duration })

      return Promise.reject(error)
    },
  )
}