import { PaginateButton } from './components/PaginateButton'
import styles from './Paginate.module.css'

interface IPaginate {
  perPage: number
  totalPage: number
  currentPage?: number
  totalElements?: number
  prevPage?: Function
  nextPage?: Function
  viewedElements?: number
}

/**
 * 
 * per_page кол-во записей на страницу
 * total кол-во записей всего
 */
const Paginate = ({
  perPage,
  totalPage,
  currentPage,
  totalElements,
  viewedElements,
  prevPage,
  nextPage,
}: IPaginate) => {
  const handleClickButtonPrev = () => {
    if (currentPage !== undefined) {
      if (currentPage-- === 0) {
        // setDisabledArrowLeft()
      }
    }
    if (prevPage) prevPage()
  }

  const handleClickButtonNext = () => {
    if (nextPage) nextPage()
  }

  const isDisabledLeftButton = () => {
    if (currentPage) {
      return currentPage !== undefined && currentPage - 1 === 0
    }
  }

  const isDisabledRightButton = () => {
    if (currentPage) {
      return currentPage !== undefined && currentPage++ >= totalPage
    }
  }

  return (
    <div className={styles.paginate}>
      <p className={styles.paginateInfo}>
        Показано <span>{viewedElements ?? 1}</span> из {totalElements}
      </p>

      <div className={styles.paginateTools}>
        <p className={styles.paginatePageInfo}>
          <span>{currentPage ?? 1}</span>/{totalPage}
        </p>
        <PaginateButton
          className={styles.paginateButtonLeft}
          onClick={handleClickButtonPrev}
          disabled={isDisabledLeftButton()}
          icon={() => (
            <svg
              width='27'
              height='50'
              viewBox='0 0 27 50'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M22.3333 4L6 25L22.3333 46'
                strokeWidth='8'
                strokeLinecap='round'
              />
            </svg>
          )}
        />

        <PaginateButton
          className={styles.paginateButtonRight}
          onClick={handleClickButtonNext}
          disabled={isDisabledRightButton()}
          icon={() => (
            <svg
              width='27'
              height='50'
              viewBox='0 0 27 50'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4.66667 4L21 25L4.66667 46'
                strokeWidth='8'
                strokeLinecap='round'
              />
            </svg>
          )}
        />
      </div>
    </div>
  )
}

export { Paginate }
