import { DeviceCard } from '@/components/DeviceCard'
import Header from '@/components/Header'
import InfoBox from '@/components/InfoBox'
import Counter from './components/Counter'
import StatusCard from '../StatusCard'
import {
  getColorStatusAutomatic,
  getColorStatusZones,
} from '@/shared/globalMethods/getColorToStatus'
import RoomMenu from './components/RoomMenu'
import menu from '@/assets/icons/small-menu.svg'
import { useState } from 'react'
import useClickOutside from '@/shared/hooks/useClickOutside'
import { $axios } from '@/shared/api'
import ModalMap from '../ModalMap'
import { sortByStatus } from '@/shared/globalMethods/sorting'
import './UniversalRoom.css'
import { TZone } from '@/types'

type UniversalRoomProps = TZone & {
  type?: string // room | zone
}

const UniversalRoom = ({
  type = 'room',
  devices,
  item,
}: UniversalRoomProps) => {
  const countDevices = devices.length
  const [isOpenMenu, setOpenMenu] = useState(false)
  const [isOpenMap, setOpenMap] = useState(false)
  const menuRef = useClickOutside(() => handleClickMenuClose())

  const handleClickMenuOpen = () => {
    setOpenMenu(true)
  }

  const handleClickMenuClose = () => {
    setOpenMenu(false)
  }

  const openModalMap = () => {
    setOpenMap(true)
  }

  const closeModalMap = () => {
    setOpenMap(false)
  }

  const handleClickSendCommand = async (command: string) => {
    await $axios.post(`/api/${type}s/${item.id}/${command}`)
  }

  return (
    <div className='room-page'>
      <Header
        zoneNum={item.zone_num}
        title={item.room_num}
        subtitle={item.room_name}
      />
      <div className='room-page__wrapper'>
        <div className='room-page__devices'>
          {devices.length ? (
            devices.sort(sortByStatus).map((item: any) => {
              return <DeviceCard key={item.id} {...item} />
            })
          ) : (
            <p>Устройства не найдены</p>
          )}
        </div>
        <div className='room-page__info'>
          <div className='room-page__info-main'>
            <InfoBox
              title='Состояние помещения'
              subtitle={
                <StatusCard
                  status={item.status}
                  style={{
                    background: getColorStatusZones(item.status),
                  }}
                />
              }
            />
            <InfoBox
              title='Состояние пожаротушения'
              subtitle={
                <StatusCard
                  status={item.firefighting_status}
                  style={{
                    background: getColorStatusAutomatic(
                      item.firefighting_status,
                    ),
                  }}
                />
              }
            />

            <div className='room-page__info-side'>
              <InfoBox title='Расположение' subtitle={item.room_name} />
            </div>
          </div>

          <div className='room-page__toolbar'>
            <div className='toolbar__right-buttons'>
              <button
                className='secondary-button'
                onClick={() => handleClickSendCommand('disable')}
              >
                отключить
              </button>
              <button className='secondary-button' onClick={openModalMap}>
                показать на плане
              </button>
            </div>
          </div>
        </div>
        <img
          className='room-page__toggle-menu'
          onClick={handleClickMenuOpen}
          src={menu}
          alt='меню'
        />

        <RoomMenu
          id={item.id}
          isOpen={isOpenMenu}
          type={type}
          closeSmallMenu={handleClickMenuClose}
          showRoomInMap={openModalMap}
          reference={menuRef}
        />
      </div>

      <ModalMap isOpen={isOpenMap} closeModal={closeModalMap} />
    </div>
  )
}

export default UniversalRoom
