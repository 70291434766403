import { IconProps } from '@/types'

const Light = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_3994_7393)'>
        <path
          d='M21.0001 17.1494C19.3104 17.1494 17.8731 18.2479 17.3594 19.7691H24.6408C24.1272 18.2479 22.6897 17.1494 21.0001 17.1494Z'
          fill={color}
        />
        <path
          d='M21.0001 24.8507C22.6305 24.8507 24.026 23.8279 24.5834 22.3896H17.4168C17.9743 23.8279 19.3698 24.8507 21.0001 24.8507Z'
          fill={color}
        />
        <path
          d='M21.0001 8.46582C14.0992 8.46582 8.48492 14.0885 8.48492 20.9999C8.48492 27.9112 14.0993 33.5339 21.0001 33.5339C27.901 33.5339 33.5153 27.9112 33.5153 20.9999C33.5153 14.0885 27.901 8.46582 21.0001 8.46582ZM30.1595 22.389H27.3113C26.767 24.8692 24.7984 26.8211 22.3099 27.3359V29.9672C22.3099 30.6906 21.7235 31.2771 21 31.2771C20.2766 31.2771 19.6901 30.6907 19.6901 29.9672V27.3359C17.2016 26.8211 15.2331 24.8692 14.6888 22.389H11.6931C10.9697 22.389 10.3832 21.8026 10.3832 21.0791C10.3832 20.3557 10.9696 19.7692 11.6931 19.7692H14.6559C15.148 17.2215 17.137 15.202 19.6675 14.6689V11.68C19.6675 10.9566 20.2539 10.3701 20.9774 10.3701C21.7008 10.3701 22.2872 10.9565 22.2872 11.68V14.659C24.8393 15.1774 26.8491 17.2062 27.3443 19.7691H30.1596C30.883 19.7691 31.4695 20.3555 31.4695 21.079C31.4694 21.8026 30.883 22.389 30.1595 22.389Z'
          fill={color}
        />
        <path
          d='M21.0001 0C9.41789 0 0.0285645 9.40204 0.0285645 21C0.0285645 32.598 9.41781 42 21.0001 42C32.5824 42 41.9716 32.598 41.9716 21C41.9716 9.40204 32.5824 0 21.0001 0ZM21.0001 36.1537C12.6547 36.1537 5.86519 29.3558 5.86519 21C5.86519 12.6442 12.6547 5.84631 21.0001 5.84631C29.3455 5.84631 36.135 12.6442 36.135 21C36.135 29.3558 29.3455 36.1537 21.0001 36.1537Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_3994_7393'>
          <rect width='42' height='42' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Light
