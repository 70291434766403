import { useLocation } from 'react-router-dom'
import ViewRoomsLink from './components/ViewRoomsLink.tsx/ViewRoomsLink'
import styles from './ViewRooms.module.css'

export type ViewRoomsProps = {
  isShow: boolean
}

/**
 * rooms // zones
 */

const ViewRooms = ({ isShow }: ViewRoomsProps) => {
  const currentPath = useLocation()

  if (!isShow) return null

  return (
    <div className={styles.row}>
      <ViewRoomsLink to={`/all/rooms${currentPath.search}`} iconName='list' />
      <ViewRoomsLink to={`/all/zones${currentPath.search}`} iconName='tile' />
    </div>
  )
}

export default ViewRooms
