import { is8InchScreen } from '@/shared/stylesJS/breakpoints'
import { NavLink } from 'react-router-dom'
import Icon from '@/assets/icons/Icon'

type ViewRoomsLinkProps = {
  to: string
  iconName: string
}
const ViewRoomsLink = ({ to, iconName }: ViewRoomsLinkProps) => {
  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <Icon
          name={iconName}
          size={is8InchScreen ? 30 : 72}
          color={isActive ? 'var(--main-color)' : 'var(--disabled-color)'}
        />
      )}
    </NavLink>
  )
}

export default ViewRoomsLink
