import { memo, useState } from 'react'


import { errorsHandler } from '@/shared/globalMethods/errorsHander'
import useClickOutside from '@/shared/hooks/useClickOutside'
import { ObjectToArray } from '@/shared/helpers'
import { $axios } from '@/shared/api'

import GenerateTestEvent from './components/GenerateTestEvent'
import HeaderBackButton from './components/HeaderBackButton'
import ServicesList from './components/ServicesList'
import MainMenu from '@/components/MainMenu'
import SmallClock from '@/components/SmallClock'

import burger from '@/assets/icons/main-menu.svg'
import { useLocation } from 'react-router-dom'
import ViewRooms from './components/ViewRooms'
import './Header.css'

type HeaderProps = {
  title: string
  subtitle?: string
  backPath?: string
  zoneNum?: string | number
}

const Header = ({ title, subtitle, backPath, zoneNum }: HeaderProps) => {
  const menuRef = useClickOutside(() => closeMainMenu())
  const path = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  // const [isOpenModal, setIsOpenModal] = useState<TModalInfo>({
  //   state: false,
  //   data: null,
  //   services: null,
  // })

  const closeMainMenu = () => {
    setIsOpen(false)
  }



  const handleMenuClick = () => {
    setIsOpen(true)
  }

  const isShowRoomsZones = (): boolean => {
    return path.pathname === '/all/rooms' || path.pathname === '/all/zones'
  }



  return (
    <div className='app-body__header'>
      <div className='header__navigation'>
        <HeaderBackButton backPath={backPath} />
        {zoneNum && <span className='header_info-box'>{zoneNum}</span>}
        <div className='header__text'>
          <h2>{title}</h2>
          {subtitle && <p>{subtitle}</p>}
        </div>
      </div>

      <div className={isOpen ? 'main-menu__overlay' : ''}></div>

      <div className='header__right-tools'>
        {window.innerWidth > 1300 && (
          <>
            <ViewRooms isShow={isShowRoomsZones()} />
            <SmallClock />
          </>
        )}
        <button onClick={handleMenuClick}>
          <img src={burger} alt='меню' />
        </button>
      </div>


      <div
        className={`main-menu ${isOpen ? 'main-menu_open' : 'main-menu_close'}`}
        ref={menuRef}
      >
        <MainMenu handleClickClose={() => setIsOpen(false)} />
      </div>

    </div>
  )
}

export default memo(Header)
