import { audioPlayHandler } from '@/shared/globalMethods/audio/audioPlayHandler'
import { showBackendNotifications } from '@/shared/globalMethods/showBackendNotifications'
import { createSlice } from '@reduxjs/toolkit'

const mqttSlice = createSlice({
  name: 'mqtt',
  initialState: {
    isConnected: false,
    messages: [] as any[],
    customSoundURL: null,
    isOpen: false,
    modalTitle: '',
    modalText: '',
    isAlarm: false,
    path: '',
    liveDataDevice: [] as any[],
    key: '',
  },
  reducers: {
    setConnected(state, action) {
      state.isConnected = Boolean(action.payload)
    },
    closeModal(state) {
      state.isOpen = false
      state.modalTitle = ''
      state.modalText = ''
    },
    removeRFIDkey(state) {
      state.key = ''
    },
    removePath(state) {
      state.path = ''
    },
    addMessage(state, action) {
      const data = JSON.parse(action.payload.payloadString)
      const audio = document.getElementById('main-player')
      const isDebuggingPage = window.location.href.includes('debugging')

      if (data.event === 'alarm') {
        state.isAlarm = true
       
        switch (data.sound) {
          case 'alarm2' : {
            state.customSoundURL = isDebuggingPage ? '' : require('../../assets/audio/alarm2.mp3')
            break
          }

          case 'alarm3' : {
            state.customSoundURL = isDebuggingPage ? '' : require('../../assets/audio/alarm3.mp3')
            break
          }

          case 'alarm4' : {
            state.customSoundURL = isDebuggingPage ? '' : require('../../assets/audio/alarm4.mp3')
            break
          }

          default: {
            state.customSoundURL = isDebuggingPage ? '' : require('../../assets/audio/alarm.mp3')
            break
          }
        }
        audioPlayHandler({ audio: audio })
      }

      if (data.event === 'warning') {
        state.customSoundURL = isDebuggingPage ? '' :require('../../assets/audio/warning.mp3')
        audioPlayHandler({ audio: audio })
      }

      if (data.command) {
        if (data.command === 'play') {
          state.customSoundURL = data.url
          audioPlayHandler({ audio: audio })
        }

        if (data.command === 'modal') {
          state.isOpen = true
          const { title, text } = data
          state.modalTitle = title
          state.modalText = text
        }

        if (data.command === 'notification') {
          showBackendNotifications(data)
        }

        if (data.command === 'live_data') {
          state.liveDataDevice.unshift(data)
        }

        if (data.command === 'rfid') {
          state.key = String(data.key)
        }
      }

      if (data.path) {
        state.path = data.path
      }
    },
  },
})

export const { setConnected, addMessage } = mqttSlice.actions
export default mqttSlice
