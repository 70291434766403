import CustomButton from '@/components/CustomButton'
import CustomInput from '@/components/CustomInput'
import { useNavigate } from 'react-router-dom'
import { TUniversalDeviceOptionMash } from '@/types'
import InfoBox from '@/components/InfoBox'

const UniversalDeviceOptionMash = ({ mash }: TUniversalDeviceOptionMash) => {
  const navigate = useNavigate()

  const handleClickButtonNavigateToMash = () => {
    navigate(`/topology/devices-all/${mash.id}`)
  }

  return (
    <div className='universal-device-options__grid'>
      <div className='universal-device-options__grid-column'>
        <InfoBox
          title='Системый номер'
          subtitle={'154854321'}
        />
        <InfoBox
          title='Серийный номер'
          subtitle={mash.sn}
        />

        <CustomButton
          value={mash.devices_count}
          onClick={handleClickButtonNavigateToMash}
          title='Кол-во АУ'
        />
      </div>

      <div className='universal-device-options__grid-column'>
        <CustomInput value={'95'} title='Порог 2 (АУ)' onChange={() => {}} />
        <CustomInput value={'95'} title='Порог 1 (АУ)' onChange={() => {}} />
        <CustomInput value={'95'} title='Период поиска' onChange={() => {}} />
      </div>

      <div className='universal-device-options__grid-column'>
        <CustomInput value={'95'} title='Ширина импульса' onChange={() => {}} />
        <CustomInput value={'95'} title='Ширина паузы' onChange={() => {}} />
        <CustomInput value={'95'} title='Период опроса' onChange={() => {}} />
      </div>

      <div className='universal-device-options__grid-column'>
        <CustomInput value={'95'} title='Кол-во АУ на входе' onChange={() => {}} />
        <CustomInput value={'95'} title='Кол-во АУ на выходе' onChange={() => {}} />
      </div>
    </div>
  )
}

export default UniversalDeviceOptionMash
