import { useEffect, useState } from 'react'
import styles from './SmallClock.module.css'

const SmallClock = () => {
  const [time, setTime] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <p className={styles.title}>
      {time.toLocaleTimeString('ru-RU', {
        hour: '2-digit',
        minute: '2-digit',
      })}
    </p>
  )
}

export default SmallClock
