import SmallMenu from '@/components/SmallMenu'
import { $axios } from '@/shared/api'
import { TDeviceMenu } from '@/types'
import MenuButton from '../Buttons/MenuButton'
import { useNavigate } from 'react-router-dom'
import ModalWithTitle from '../ModalWithTitle'
import { NumPad } from '../NumPad'
import { useEffect, useState } from 'react'
import { errorsHandler } from '@/shared/globalMethods/errorsHander'
import { useDispatch, useSelector } from 'react-redux'
import mqttSlice from '@/store/reducers/mqttSlice'

type ErrorProps = {
  error: string
  status: 'error'
}

type SuccessProps = {
  status: 'success'
}

const DeviceMenu = ({
  id,
  isOpen,
  reference,
  closeSmallMenu,
  showRoomInMap,
}: TDeviceMenu) => {
  const mqttStore = useSelector((state: any) => state.mqtt)
  const navigate = useNavigate()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [key, setKey] = useState('')
  const [commandItem, setCommandItem] = useState('')
  const dispatch = useDispatch()

  const openModal = () => setIsOpenModal(true)
  const closeModal = () => setIsOpenModal(false)

  useEffect(() => {
    if (isOpenModal && mqttStore.key) {
      setKey(mqttStore.key)
      checkNumPad(mqttStore.key)
    }

    removeRFIDkeyFromStore()
  }, [mqttStore, isOpenModal])

  const handleClickManageDevice = async (
    command: string,
    body?: { [key: string]: string },
  ) => {
    await $axios
      .post(`/api/devices/${id}/${command}`, body)
      .then(({ data }: { data: SuccessProps | ErrorProps }) => {
        if (data.status === 'error') {
          errorsHandler(data.error)
        }
      })
  }

  const onChangeNumpad = (name: string, value: string) => {
    setKey(value)
  }

  const handleClickManageDeviceWithKey = (command: string) => {
    setCommandItem(command)
    openModal()
  }

  const removeRFIDkeyFromStore = () => {
    dispatch(mqttSlice.actions.removeRFIDkey(mqttStore))
  }

  const removeData = () => {
    setCommandItem('')
    setKey('')
    removeRFIDkeyFromStore()
  }

  const closeNumPad = () => {
    closeModal()
    removeData()
  }

  const checkNumPad = (storeKey?: string) => {
    handleClickManageDevice(commandItem, {
      key: storeKey ? storeKey : key,
    })
    closeModal()
    removeData()
  }

  return (
    <SmallMenu
      isOpen={isOpen}
      reference={reference}
      onClick={() => closeSmallMenu()}
    >
      <MenuButton
        iconName='light'
        title='Вкл. красный'
        onClick={() => handleClickManageDevice('led_on_red')}
      />

      <MenuButton
        iconName='light'
        title='Вкл. зеленый'
        onClick={() => handleClickManageDevice('led_on_green')}
      />

      <MenuButton
        iconName='light'
        title='Вкл. желтый'
        onClick={() => handleClickManageDevice('led_on_yellow')}
      />

      <MenuButton
        iconName='light'
        title='Выкл. светодиод'
        onClick={() => handleClickManageDevice('led_off')}
      />

      <MenuButton
        iconName='check-note'
        title='тест устройства'
        onClick={() => handleClickManageDevice('fire-simulation')}
      />

      <MenuButton
        iconName='reset'
        title='сброс устройства'
        onClick={() => handleClickManageDeviceWithKey('reset')}
      />

      <MenuButton
        iconName='unconnected'
        title='отключить'
        onClick={() => handleClickManageDeviceWithKey('disable')}
      />

      <MenuButton
        iconName='log'
        title='лог устройства'
        onClick={() =>
          navigate(`/events?page=1&filter=device_id&device_id=${id}`)
        }
      />

      <MenuButton
        iconName='map-point'
        title='показать на плане'
        onClick={() => {
          closeSmallMenu()
          if (showRoomInMap) showRoomInMap()
        }}
      />

      <ModalWithTitle
        title='введите пароль'
        content={
          <NumPad
            value={key}
            name={'key'}
            onClose={closeNumPad}
            onChange={onChangeNumpad}
            onCheck={checkNumPad}
          />
        }
        isOpen={isOpenModal}
        closeModal={closeModal}
      />
    </SmallMenu>
  )
}

export { DeviceMenu }
