import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import API_BASE_URL from '../apiBaseURL'

export const topologyApi = createApi({
  reducerPath: 'topologyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
  }),
  endpoints: build => ({
    fetchTolopogy: build.query({
      query: () => `/api/topology`,
    }),
  }),
})
