import { getColorStatusZones } from '@/shared/globalMethods/getColorToStatus'
import { OperatorStatus } from '@/types'
import styles from './EventCountCard.module.css'

type EventCountCardProps = OperatorStatus & {}

const EventCountCard = ({ event, count }: EventCountCardProps) => {
  return (
    <div className={styles.card}>
      <p className={styles.card__title}>{event}</p>
      <div
        className={styles.card__body}
        style={{ borderColor: getColorStatusZones(event) }}
      >
        <h2 style={{ background: getColorStatusZones(event) }}>{count}</h2>
        <p>статусов</p>
      </div>
    </div>
  )
}

export default EventCountCard
