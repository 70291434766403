/**
 * более новая функция работает на атрибуте style
 * и св-ве background, color, border-color...
 *
 * ПРИМЕР:
 * style={{
 * background: getColorStatusZones(status)}}
 */

export const getColorStatusZones = (status: string | null): string => {
  if (!status) {
    return 'var(--offline-color)'
  }

  const trimStatus = status?.trim().toLowerCase()

  if (trimStatus === 'пожар2' || trimStatus === 'пожар')
    return 'var(--fire2-color)'
  if (trimStatus === 'пожар1') return 'var(--fire1-color)'
  if (
    trimStatus === 'неисправность' ||
    trimStatus === 'неиспр.' ||
    trimStatus === 'внимание'
  )
    return 'var(--malfunction-color)'
  if (
    trimStatus === 'норма' ||
    trimStatus === 'online' ||
    trimStatus === 'success'
  )
    return 'var(--success-color)'
  if (trimStatus === 'откл' || trimStatus === 'откл.')
    return 'var(--unconnection-color)'

  return 'var(--offline-color)'
}

export const getColorStatusRoom = (status: string | null): string => {
  if (!status) {
    return 'var(--offline-color)'
  }

  const trimStatus = status?.trim().toLowerCase()

  if (
    trimStatus === 'пожар2' ||
    trimStatus === 'alarm' ||
    trimStatus === 'пожар'
  )
    return 'var(--fire2-color)'
  if (trimStatus === 'пожар1') return 'var(--fire1-color)'
  if (trimStatus === 'неисправность') return 'var(--malfunction-color)'
  if (
    trimStatus === 'норма' ||
    trimStatus === 'online' ||
    trimStatus === 'success'
  )
    return 'var(--success-color)'
  if (trimStatus === 'откл') return 'var(--unconnection-color)'

  return 'var(--offline-color)'
}

export const getColorStatusDevice = (status: string | null): string => {
  if (!status) {
    return 'var(--offline-color)'
  }

  const trimStatus = status?.trim().toLowerCase()

  if (trimStatus === 'пожар' || trimStatus === 'пожар2')
    return 'var(--fire2-color)'
  if (trimStatus === 'пожар1') return 'var(--fire1-color)'

  if (
    trimStatus === 'норма' ||
    trimStatus === 'online' ||
    trimStatus === 'success'
  )
    return 'var(--success-color)'
  if (trimStatus === 'откл') return 'var(--unconnection-color)'
  if (trimStatus === 'сбой') return 'var(--malfunction-color)'

  return 'var(--offline-color)'
}

export const getColorCategoryEvent = (category: string | null): string => {
  if (!category) {
    return 'var(--offline-color)'
  }

  const trimStatus = category?.trim().toLowerCase()

  if (trimStatus === 'информационное' || trimStatus === 'штатное')
    return 'var(--main-color)'
  if (trimStatus === 'критическое') return 'var(--fire2-color)'
  if (trimStatus === 'не штатное') return 'var(--malfunction-color)'
  if (trimStatus === 'квитировано') return 'var(--success-color)'

  return 'var(--offline-color)'
}

export const getColorStatusAutomatic = (status: string) => {
  if (!status) {
    return 'var(--offline-color)'
  }

  const trimStatus = status?.trim().toLowerCase()

  if (trimStatus === 'пуск') return 'var(--fire2-color)'
  if (trimStatus === 'останов. пуска') return 'var(--unconnection-color)'
  if (trimStatus === 'авт. отключена') return 'var(--malfunction-color)'
  if (trimStatus === 'авт. включена') return 'var(--success-color)'
  if (
    trimStatus === 'порошок' ||
    trimStatus === 'вода' ||
    trimStatus === 'газ' ||
    trimStatus === 'пена' ||
    trimStatus === 'аэрозоль'
  )
    return 'var(--automatic3-color)'

  return 'var(--offline-color)'
}
