import styles from './MainMenu.module.css'
import logo from '@/assets/icons/logo.svg'

import close from '@/assets/icons/main-menu_close.svg'
import user from '@/assets/icons/user.svg'
import { errorsHandler } from '@/shared/globalMethods/errorsHander'
import { useNavigate } from 'react-router-dom'
import { $axios } from '@/shared/api'
import MenuButton from '@/components/Buttons/MenuButton'
import { is8InchScreen } from '@/shared/stylesJS/breakpoints'

interface IMainMenu {
  handleClickClose: any
}

const MainMenu = ({ handleClickClose }: IMainMenu) => {
  const navigate = useNavigate()

  const resetAlarm = () => {
    return $axios.post('/api/reset-alarm')
  }

  return (
    <div className={styles.mainmenu}>
      {is8InchScreen ? (
        <div className={styles.mainmenu_header}>
          <img src={user} alt='пользователь' />
          <h3>Смолин В. Г.</h3>
        </div>
      ) : (
        <div className={styles.mainmenu_header}>
          <img className={styles.mainmenu_logo} src={logo} alt='Логотип' />
          <img
            className={styles.mainmenu_close}
            src={close}
            onClick={handleClickClose}
            alt='close'
          />
        </div>
      )}

      <div className={styles.mainmenu_groupbuttons} onClick={handleClickClose}>
        <MenuButton
          iconName='settings-instruments'
          title='Настройки'
          onClick={() => navigate('/settings')}
        />

        <MenuButton
          iconName='document-defence'
          title='смена дежурства'
          onClick={() => navigate('/operator-change')}
        />

        <MenuButton
          iconName='reset'
          title='сброс тревоги'
          onClick={resetAlarm}
        />

        <MenuButton iconName='help' title='помощь' />
      </div>
      <div className={styles.mainmenu_footer}>
        <span>версия по {process.env.REACT_APP_VERSION}</span>
      </div>
    </div>
  )
}

export default MainMenu
