import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import API_BASE_URL from '../apiBaseURL'

export const zonesApi: any = createApi({
  reducerPath: 'zones',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
  }),
  endpoints: (build: any) => ({
    getZones: build.query({
      query: (zoneId: string) => `/api/zones${zoneId ? `/${zoneId}` : ''}`,
    }),
  }),
})

export const { useGetZonesQuery } = zonesApi
export default zonesApi
