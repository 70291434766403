import { toastStyle } from '../stylesJS/toastStyle'
import { ToastOptions } from 'react-toastify'
import { toast } from 'react-toastify'

interface IShowBackendNotifications {
  title: string
  type: string
  timeOut?: number
}

export const showBackendNotifications = ({
  title,
  timeOut,
  type,
}: IShowBackendNotifications) => {
  const styleBackendNotification: ToastOptions = {
    ...toastStyle,
    autoClose: timeOut ?? 5000,
  }

  switch (type) {
    case 'error':
      toast.error(title, styleBackendNotification)
      break
    case 'warning':
      toast.warning(title, styleBackendNotification)
      break
    case 'success':
      toast.success(title, styleBackendNotification)
      break
    case 'info':
      toast.info(title, styleBackendNotification)
      break
    default:
      toast(title, styleBackendNotification)
  }
}
