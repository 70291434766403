import { IconProps } from '@/types'

const Reset = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='IconPack-1'>
        <path
          id='Vector'
          d='M4.375 17.325V5.25L7.7 8.575C10.85 5.425 15.225 3.5 20.125 3.5C29.75 3.5 37.625 11.375 37.625 21C37.625 30.625 29.75 38.5 20.125 38.5C15.225 38.5 10.85 36.575 7.7 33.425L12.6 28.525C14.525 30.45 17.15 31.675 19.95 31.675C25.725 31.675 30.45 26.95 30.45 21.175C30.45 15.4 25.725 10.675 19.95 10.675C16.975 10.675 14.35 11.9 12.6 13.825L16.275 17.5H4.375V17.325Z'
          fill={color}
        />
      </g>
    </svg>
  )
}

export default Reset
