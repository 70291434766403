import { useDispatch, useSelector } from 'react-redux'
import { Chart as ChartJS, registerables } from 'chart.js'
import { useEffect, useState } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import { ToastContainer } from 'react-toastify'
import Modal from 'react-modal'

import { setDefaultStateSound } from '@/shared/globalMethods/audio/setDefaultStateSound'
import { getDefaultStateSound } from '@/shared/globalMethods/audio/getDefaultStateSound'
import { stopAllAudioAndMute } from '@/shared/globalMethods/audio/stopAllAudioAndMute'
import mqttSlice from '@/store/reducers/mqttSlice'

import { Navigator } from '@/components/Navigator'
import { Sidebar } from '@/components/Sidebar'

import { modalMapStyles } from '@/shared/stylesJS/modalStyle'
import styles from './App.module.css'
import './App.css'
import { useNavigate } from 'react-router-dom'

Modal.setAppElement('#root')

const App = () => {
  ChartJS.register(...registerables)
  const dispatch = useDispatch()
  const mqttStore = useSelector((state: any) => state.mqtt)
  const navigate = useNavigate()

  const [isSoundOn, setIsSoundOn] = useState<number>(getDefaultStateSound())

  const toggleSound = (volume: number) => {
    setIsSoundOn(state => (state = volume))
    setDefaultStateSound(volume)

    if (!volume) {
      stopAllAudioAndMute()
    }
  }

  const closeModal = () => {
    dispatch(mqttSlice.actions.closeModal(mqttStore))
  }

  useEffect(() => {
    dispatch({ type: 'mqtt/connect' })
  }, [dispatch])

  useEffect(() => {
    if (mqttStore.path) {
      navigate(mqttStore.path)
      dispatch(mqttSlice.actions.removePath(mqttStore))
    }
  }, [mqttStore])

  return (
    <div className='app-wrapper'>
      <ReactAudioPlayer
        id='main-player'
        src={mqttStore.customSoundURL}
        autoPlay
        volume={getDefaultStateSound()}
      />
      <ToastContainer />
      <Sidebar isSoundOn={isSoundOn} setIsSoundOn={toggleSound} />

      <div id='body' className='app-body'>
        <Navigator />
      </div>

      <Modal
        isOpen={mqttStore.isOpen}
        onRequestClose={closeModal}
        style={modalMapStyles}
        className={styles.modal}
      >
        <div style={{ height: 700, padding: 24 }}>
          <div dangerouslySetInnerHTML={{ __html: mqttStore.modalTitle }} />
          <div dangerouslySetInnerHTML={{ __html: mqttStore.modalText }} />
        </div>
      </Modal>
    </div>
  )
}

export { App }
