import { createSlice } from '@reduxjs/toolkit'

type TInitialState = {
  isOpen: boolean
  value: string
}

const initialState: TInitialState = {
  isOpen: false,
  value: '',
}

const commonInputModal = createSlice({
  name: 'commonInputModal',
  initialState,
  reducers: {
    openCommonInputModal(state: TInitialState, { payload }) {
      state.isOpen = true
      state.value = payload.value
    },
    closeCommonInputModal: () => initialState,
    setCommonInputModalValue(state, { payload }) {
      state.value = payload
    },
  },
})

export default commonInputModal

export const {
  openCommonInputModal,
  closeCommonInputModal,
  setCommonInputModalValue,
} = commonInputModal.actions
