import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import styles from './UniversalDeviceTabs.module.css'

const UniversalDeviceTabs = ({ tabs,currentTab, sizeTabs, setTabIndex }: any) => {

  const getActiveStyle = (id: string) => {
    return id === currentTab ? styles.active : ''
  }

  const getSizeStyle = () => {
    switch (sizeTabs) {
      case 'large':
        return styles.link_large
      default:
        return styles.link_default
    }
  }

  if (!tabs || !tabs.length) return null
const setTabIndexHandler = (id: string) => {
  if (id === currentTab) return
  setTabIndex(id)
}
  return (
    <TransformWrapper
      initialScale={1}
      minScale={1}
      maxScale={1}
      panning={{
        lockAxisY: true,
      }}
      initialPositionX={0}
      initialPositionY={0}
      wheel={{ step: 100 }}
      pinch={{ disabled: false }}
    >
      {utils => (
        <div style={{ overflow: 'hidden' }}>
          <TransformComponent>
            <div
              className={`
            ${styles.universalDeviceTabs}
                    
                    ${getSizeStyle()}`}
            >
              {tabs.map(({ title, id }: any) => (
                <a

                  key={id}
                  onClick={() => setTabIndexHandler(id)}
                  className={getActiveStyle(id)}
                >
                  {title}
                </a>
              ))}
            </div>
          </TransformComponent>
        </div>
      )}
    </TransformWrapper>
  )
}

export default UniversalDeviceTabs
