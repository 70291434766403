import { Navigate, Route, Routes } from 'react-router-dom'
import { Device } from '../../pages/Device'
import { Events } from '../../pages/Events'
import { Event } from '../../pages/Events/Event'
import Topology from '@/pages/Topology'
import TopologyItem from '@/pages/Topology/TolologyItem/TopologyItem'
import { TopologyAll } from '../../pages/TopologyAll'
import { Zone } from '../../pages/Zones/Zone'
import { Zones } from '../../pages/Zones'
import './Navigator.css'

import AppConfigurate from '@/pages/AppConfigurate'
import ActiveEvents from '@/pages/ActiveEvents'
import Rooms from '@/pages/Rooms'
import Room from '@/pages/Rooms/Room'
import Dashboard from '@/pages/Dashboard'
import SettingsNetwork from '@/pages/SettingsNetwork'
import TimeRequests from '@/pages/TimeRequests'
import OperatorChange from '@/pages/OperatorChange'
import Settings from '@/pages/Settings'
import Debugging from '@/pages/Debugging'

const Navigator = () => {
  return (
      <Routes>
        <Route path='/' element={<Navigate to='/dashboard' />} />
        <Route path='/all'>
          <Route path='/all' element={<Navigate to='/all/rooms' />} />
          <Route path='/all/zones' element={<Zones />} />
          <Route path='/all/zones/:zone_id' element={<Zone />} />
          <Route path='/all/zones/:zone_id/device/:id' element={<Device />} />
          
          <Route index path='/all/rooms' element={<Rooms />} />
          <Route path='/all/rooms/:room_id' element={<Room />} />
          <Route path='/all/rooms/:room_id/device/:id' element={<Device />} />
        </Route>

        <Route path='/active-events' element={<ActiveEvents />}>
          
        </Route>
        <Route path='/active-events/device/:id' element={<Device />}/>

        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/topology'>
          <Route path='/topology' element={<Topology />} />
          <Route path='/topology/:ppky_id' element={<TopologyItem />} />
          <Route
            path='/topology/devices-all/:mash_id'
            element={<TopologyAll />}
          />
        </Route>
        <Route
          path='/topology/devices-all/:mash_id/device/:id'
          element={<Device />}
        />

        <Route path='/events'>
          <Route path='/events' element={<Events />} />
          <Route path='/events/:event_id' element={<Event />} />
        </Route>

        <Route path='/settings' element={<Settings/>} />
        <Route path='/operator-change' element={<OperatorChange/>} />
        <Route path='/settings-network' element={<SettingsNetwork />} />
        <Route path='/app-configurate' element={<AppConfigurate />} />
        <Route path='/time-requests' element={<TimeRequests />} />
        <Route path='/debugging' element={<Debugging />} />
      </Routes>
  )
}

export { Navigator }
