import { TService, TServiceList } from '@/types'
import Service from './components/Service'


const ServicesList = ({ services }: TServiceList) => {
  if (!services) return null
  return (
    <div>
      {services.map((serive: TService) => (
        <Service key={serive.name} {...serive} />
      ))}
    </div>
  )
}

export default ServicesList
