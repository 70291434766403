import { modalNumPadStyles } from '@/shared/stylesJS/modalStyle'
import ReactModal from 'react-modal'
import styles from './ModalWithTitle.module.css'

type ModalWithTitleProps = {
  isOpen: boolean
  title: string
  content: React.ReactNode
  closeModal: any
}

const ModalWithTitle = ({
  isOpen,
  title,
  content,
  closeModal,
}: ModalWithTitleProps) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={modalNumPadStyles}
    >
      <div className={styles.wrapper}>
        <span className={styles.title}>{title}</span>
        <div className={styles.body}>{content}</div>
      </div>
    </ReactModal>
  )
}

export default ModalWithTitle
