import { useSelector } from 'react-redux'

import { SidebarItem } from './components/SidebarItem'
import { Clock } from './components/Clock'

import { sidebarItems } from '@/shared/dataComponents/sidebarItems'
import soundOff from '@/assets/icons/sound-off.svg'
import soundOn from '@/assets/icons/sound-on.svg'
import './Sidebar.css'
import { NavLink, To, useLocation } from 'react-router-dom'
import { TSidebarState } from '@/types'
import { useEffect, useState } from 'react'
import { $axios } from '@/shared/api'

interface ISidebar {
  isSoundOn: number
  setIsSoundOn: Function
}

const Sidebar = ({ isSoundOn, setIsSoundOn }: ISidebar) => {

  const [sidebarState, setSidebarState] = useState<any>()
  const reload = useSelector((state: any) => state.reload)

  const fetchSidebarState = async () => {
    const {data} = await $axios.get('/api/status')
    setSidebarState(data)
  }

  useEffect(() => {
    fetchSidebarState()
  }, [reload])

  const { pathname, search } = useLocation()

  const getStatusFromQuery = (queryString: string) => {
    const urlParams = new URLSearchParams(queryString)
    return urlParams.get('status')
  }

  const pathСomparison = (path: any): boolean => {
    const status = getStatusFromQuery(path.search?.toString())

    if (status && search) {
      // есть status search params
      const decodeSearch = decodeURIComponent(search)
      if (decodeSearch.includes(status)) {
        return true
      }

      return false
    } else {
      // нет status в search params
      if (!status && !search && pathname.includes(path.pathname.toString())) {
        return true
      }
    }

    return false
  }

  const isEnabledSidebarItem = (name: string): boolean => {
    return sidebarState?.zones_statuses[name]
  }

  return (
    <div className='sidebar'>
      <img
        className='sidebar__volume'
        src={isSoundOn ? soundOn : soundOff}
        onClick={() => setIsSoundOn(Number(!isSoundOn))}
        alt='soundSwithIndicate'
      />

      <div className='sidebar__navigation'>
        {sidebarItems.map(item => (
          <SidebarItem
            key={item.title}
            isEnabled={isEnabledSidebarItem(item.name)}
            isActive={pathСomparison(item.path)}
            {...item}
          />
        ))}
      </div>
    </div>
  )
}
export { Sidebar }
