import { createSlice } from '@reduxjs/toolkit'

type TReloadSlice = {
  reload: boolean
}
const initialState: TReloadSlice = {
  reload: false,
}

const reloadSlice = createSlice({
  name: 'reload',
  initialState,
  reducers: {
    // тут вообще всеравно true, false лишь бы изменялось
    tooggleReload(state, action) {
      state.reload = !state.reload
    },
  },
})

export const { tooggleReload } = reloadSlice.actions

export default reloadSlice
