import { ActiveEventCardProps } from '@/types'
import { is8InchScreen } from '@/shared/stylesJS/breakpoints'
import ActiveEventCard8Inch from './ActiveEventCard8Inch'
import ActiveEventCard9Inch from './ActiveEventCard9Inch/ActiveEventCard9Inch'

const ActiveEventCard = (props: ActiveEventCardProps) => {
  if (is8InchScreen) {
    return <ActiveEventCard8Inch {...props} />
  } else return <ActiveEventCard9Inch {...props} />
}

export default ActiveEventCard
