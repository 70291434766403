import UniversalDeviceOptionMash from './components/Options/UniversalDeviceOptionMash'
import UniversalDeviceTabs from './components/UniversalDeviceTabs'
import UniversalDeviceInfo from './components/UniversalDeviceInfo'
import Header from '../Header'

import { memo, useMemo, useState, useEffect } from 'react'
import { TDevice, TMash } from '@/types'

import './UniversalDeviceView.css'
import CustomButton from '../CustomButton'
import { useSelector } from 'react-redux'

type TUniversalDeviceView = {
  device: TDevice
  mash?: TMash[] | null
  updateData: Function
}

const UniversalDeviceView = memo(
  ({ device, mash, updateData }: TUniversalDeviceView) => {
    const [tabIndex, setTabIndex] = useState(0)
    const liveDataDevice = useSelector(
      (state: any) => state.mqtt.liveDataDevice,
    )

    const getName = (): string => {
      if (device?.device_type === 'ppku') {
        return `ППКПУ`
      }

      if (device?.device_type === 'kupt') {
        return `КУПТ`
      }

      return 'Пожарный извещатель'
    }

    const tabsPpku: any = []

    const tabsSensor: any = [
      // {
      //   id: 0,
      //   title: 'основные',
      //   Component: <UniversalDeviceOptionsMain />,
      // },
      // {
      //   id: 1,
      //   title: 'тестовый',
      //   Component: <UniversalDeviceOptionTest />,
      // },
      // {
      //   id: 2,
      //   title: 'Дымовой',
      //   Component: <UniversalDeviceOptionSmoke />,
      // },
      // {
      //   id: 3,
      //   title: 'Дымовой сл.',
      //   Component: <UniversalDeviceOptionSmokeSl />,
      // },
      // {
      //   id: 4,
      //   title: 'системная информация',
      //   Component: <UniversalDeviceOptionSystemInfo />,
      // },
      // {
      //   id: 5,
      //   title: 'график',
      //   Component: <UniversalDeviceChart id={device.id} />,
      // },
    ]

    const mashDevices = mash ?? []

    const getTabs = useMemo(() => {
      if (device.device_type === null && device.device_type === undefined) {
        return null
      }

      if (device.device_type === 'ppku') {
        if (!mashDevices.length) {
          return null
        }

        mashDevices.forEach((mash: any, index: number) => {
          tabsPpku.push({
            id: index,
            title: index + 1,
            Component: <UniversalDeviceOptionMash mash={mash} />,
          })
        })

        return tabsPpku
      }

      if (device.device_type === 'kupt') {
        return null
      }

      return tabsSensor
    }, [device, tabIndex])

    const getTabContent = useMemo(() => {
      if (!device.device_type) {
        return null
      }

      if (device.device_type === 'ppku') {
        return tabsPpku[tabIndex] ? tabsPpku[tabIndex].Component : null
      }

      return tabsSensor[tabIndex]?.Component
    }, [device, tabIndex, tabsPpku])

    const getSizeTabs = () => {
      return device.device_type === 'ppku' ? 'large' : 'default'
    }

    const [lastTemp, setLastTemp] = useState(0)
    // если храним 1 параметр девайса (навсякий случай, но если видишь это удали)
    // useEffect(() => {
    //   if (liveDataDevice.id === device.id) {
    //     setLastTemp(liveDataDevice.value)
    //   }
    // }, [liveDataDevice])

    useEffect(() => {
      const currentDeviceData = liveDataDevice.find(
        (item: any) => item.id === device.id,
      )
      if (currentDeviceData) {
        setLastTemp(currentDeviceData.value)
      }
    }, [liveDataDevice])

    return (
      <>
        <Header
          zoneNum={device.zone?.zone_num ? device.zone.zone_num : 'нет'}
          title={getName()}
          subtitle='для детального просмотра выберите канал'
        />
        {device ? (
          <>
            <UniversalDeviceInfo {...device} />
            {device.last_temp || lastTemp ? (
              <CustomButton
                title='Температура'
                value={lastTemp ? lastTemp : device.last_temp}
                style={{ marginBottom: 16 }}
                onClick={updateData}
              />
            ) : null}
            <UniversalDeviceTabs
              tabs={getTabs}
              currentTab={tabIndex}
              setTabIndex={setTabIndex}
              sizeTabs={getSizeTabs()}
            />
            <div className='universal-device-options'>{getTabContent}</div>
          </>
        ) : null}
      </>
    )
  },
)

export default UniversalDeviceView
