import { customInputTypes } from '@/shared/customInputTypes'
import { CSSProperties, useRef, useState } from 'react'
import styles from './CustomInput.module.css'
import ModalWithTitle from '../ModalWithTitle'
import { NumPad } from '../NumPad'

type TCustomInput = {
  value: string
  onChange: Function
  name?: string //обязательным сделай иначе чуда не произойдет
  title?: string
  type?: string
  valueType?: string
  placeholder?: string
  disabled?: boolean
  style?: CSSProperties
}

const CustomInput = ({
  name,
  title,
  value,
  type,
  placeholder,
  valueType,
  style,
  onChange,
  disabled,
}: TCustomInput) => {
  const [isOpen, setIsOpen] = useState(false)

  const getValueType = (): string => {
    if (valueType === customInputTypes.degrees) return ' °'
    if (valueType === customInputTypes.decibels) return ' dB'

    return ''
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const handleClickOpenModalNumpad = () => {
    openModal()
  }

  return (
    <>
      <div className={styles.wrapper}>
        <button
          className={styles.input}
          onClick={handleClickOpenModalNumpad}
          disabled={disabled}
          style={style}
        >
          {value + getValueType()}
        </button>
        <p>{title}</p>
      </div>
      <ModalWithTitle
        title='Введите значение'
        isOpen={isOpen}
        closeModal={closeModal}
        content={
          <NumPad
            value={value}
            type={type}
            name={name ?? ''}
            onClose={closeModal}
            onChange={onChange}
          />
        }
      />
    </>
  )
}

export default CustomInput