import { IconProps } from '@/types'

const MapPoint = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_3994_6549)'>
        <path
          d='M21 0C13.6201 0 7.46484 5.9448 7.46484 13.5352C7.46484 16.4228 8.33257 18.9978 9.99789 21.4107L19.9642 36.9621C20.4477 37.7181 21.5533 37.7167 22.0358 36.9621L32.0453 21.3577C33.6748 19.0542 34.5352 16.3494 34.5352 13.5352C34.5352 6.07187 28.4633 0 21 0ZM21 19.6875C17.6078 19.6875 14.8477 16.9274 14.8477 13.5352C14.8477 10.1429 17.6078 7.38281 21 7.38281C24.3922 7.38281 27.1523 10.1429 27.1523 13.5352C27.1523 16.9274 24.3922 19.6875 21 19.6875Z'
          fill={color}
        />
        <path
          d='M30.6193 28.2754L24.4234 37.9622C22.8196 40.4627 19.1714 40.4545 17.5754 37.9646L11.3694 28.2779C5.90904 29.5404 2.54297 31.8531 2.54297 34.6168C2.54297 39.4125 12.0527 41.9996 21 41.9996C29.9473 41.9996 39.457 39.4125 39.457 34.6168C39.457 31.8511 36.0863 29.5372 30.6193 28.2754Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_3994_6549'>
          <rect width='42' height='42' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MapPoint
