import { sortByStatus } from '@/shared/globalMethods/sorting'
import { useQuery } from '@/shared/hooks/useQuery'
import { $axios } from '@/shared/api'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Paginate } from '@/components/Paginate'
import { ZoneCard } from '@/components/ZoneCard'
import Header from '@/components/Header'

import type { TPagination, TZoneItem } from '@/types'
import styles from './Zones.module.css'
import LoaderFullScreen from '@/components/AppHelpers/LoaderFullScreen'

type TZonesOnPage = {
  pagination: TPagination
  results: TZoneItem[]
}

const Zones = () => {
  const reload = useSelector((state: any) => state.reload)
  const [data, setData] = useState<TZonesOnPage | null>(null)
  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(false)
  const query = useQuery()

  const fetchZones = async (status?: string | null) => {
    try {
      const { data } = await $axios.get(
        `/api/zones${status ? `?status=${status}` : ''}`,
      )
      setData(data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(true)
    }
  }

  useEffect(() => {
    fetchZones(query.get('status'))
  }, [reload])

  if (isLoading) return <LoaderFullScreen />

  if (isError) return <p>Возникла ошибка</p>

  if (!data) return <p>Список зон пуст</p>

  return (
    <>
      <Header
        title='обзор помещений в системе'
        subtitle='для детального просмотра выберите помещение'
        backPath='/dashboard'
      />

      <div className={styles.grid}>
        {data !== null && data !== undefined ? (
          data.results
            .sort(sortByStatus)
            .map((zone: TZoneItem) => <ZoneCard key={zone.id} {...zone} />)
        ) : (
          <p>Зон нет</p>
        )}
      </div>
      <Paginate
        totalPage={data.pagination.pages}
        currentPage={data.pagination.page}
        viewedElements={data.results.length}
        perPage={data.pagination.per_page}
        // prevPage={prevPage}
        // nextPage={nextPage}
      />
    </>
  )
}

export { Zones }
