import { IconProps } from '@/types'

const DocAndCheckbox = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.6632 2.33301H10.1033C9.39158 2.33301 8.71492 2.61301 8.21325 3.11467C6.92992 4.39801 4.39825 6.92967 3.11492 8.21301C2.61325 8.71467 2.33325 9.40301 2.33325 10.103V36.9947C2.33325 37.7063 2.61325 38.383 3.11492 38.873C3.60492 39.3747 4.29325 39.6547 4.99325 39.6547H20.9883V26.3197C20.9883 23.368 23.3799 20.988 26.3199 20.988H34.3233V5.00467C34.3233 3.52301 33.1216 2.33301 31.6516 2.33301H31.6632ZM16.9983 27.6613H8.99492C8.25992 27.6613 7.66492 27.0663 7.66492 26.3313C7.66492 25.5963 8.25992 25.0013 8.99492 25.0013H16.9983C17.7333 25.0013 18.3283 25.5963 18.3283 26.3313C18.3283 27.0663 17.7333 27.6613 16.9983 27.6613ZM16.9983 20.9997H8.99492C8.25992 20.9997 7.66492 20.4047 7.66492 19.6697C7.66492 18.9347 8.25992 18.3397 8.99492 18.3397H16.9983C17.7333 18.3397 18.3283 18.9347 18.3283 19.6697C18.3283 20.4047 17.7333 20.9997 16.9983 20.9997ZM27.6616 14.338H8.99492C8.25992 14.338 7.66492 13.743 7.66492 13.008C7.66492 12.273 8.25992 11.678 8.99492 11.678H27.6616C28.3966 11.678 28.9916 12.273 28.9916 13.008C28.9916 13.743 28.3966 14.338 27.6616 14.338Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.9949 23.6709H26.3316C24.8616 23.6709 23.6599 24.8609 23.6599 26.3426V39.6776H36.9949C38.4649 39.6776 39.6666 38.4759 39.6666 37.0059V26.3426C39.6666 24.8609 38.4649 23.6709 36.9949 23.6709ZM35.2799 30.9392L34.3349 31.8959L31.9782 34.2642C31.7216 34.5326 31.3832 34.6609 31.0332 34.6609C30.6832 34.6609 30.3799 34.5442 30.1116 34.2876L28.0816 32.3392C27.5449 31.8376 27.5332 30.9976 28.0466 30.4609C28.5482 29.9242 29.3882 29.9009 29.9249 30.4259L31.0216 31.4642L33.3899 29.0609C33.6582 28.7926 33.9849 28.6642 34.3349 28.6642C34.6849 28.6642 35.0116 28.7926 35.2682 29.0492C35.8049 29.5742 35.8049 30.4142 35.2799 30.9276V30.9392Z'
        fill={color}
      />
    </svg>
  )
}

export default DocAndCheckbox
