import { IconProps } from '@/types'

const Help = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21 3C11.0514 3 3 11.0505 3 21C3 30.9484 11.0505 39 21 39C30.9486 39 39 30.9495 39 21C39 11.0514 30.9495 3 21 3ZM21 36.4884C12.4597 36.4884 5.51163 29.5404 5.51163 21C5.51163 12.4596 12.4597 5.51163 21 5.51163C29.5404 5.51163 36.4884 12.4596 36.4884 21C36.4884 29.5404 29.5404 36.4884 21 36.4884Z'
        fill={color}
      />
      <circle cx='21' cy='21' r='17' fill={color} />
      <path
        d='M20.924 29.5918C19.8311 29.5918 18.9463 30.4967 18.9463 31.5824C18.9463 32.6425 19.805 33.5732 20.924 33.5732C22.043 33.5732 22.9277 32.6425 22.9277 31.5824C22.9277 30.4967 22.0169 29.5918 20.924 29.5918Z'
        fill='white'
      />
      <path
        d='M21.3023 10.9629C17.0572 10.9629 15.1077 13.4786 15.1077 15.1765C15.1077 16.4029 16.1453 16.9689 16.9943 16.9689C18.6924 16.9689 18.0006 14.5476 21.2079 14.5476C22.7801 14.5476 24.038 15.2394 24.038 16.6859C24.038 18.3838 22.277 19.3586 21.2394 20.239C20.3274 21.025 19.1326 22.3143 19.1326 25.0186C19.1326 26.6537 19.5729 27.1254 20.862 27.1254C22.4027 27.1254 22.7172 26.4336 22.7172 25.836C22.7172 24.201 22.7486 23.2577 24.4781 21.9055C25.3271 21.2452 27.9998 19.1069 27.9998 16.1512C27.9998 13.1954 25.3271 10.9629 21.3023 10.9629Z'
        fill='white'
      />
    </svg>
  )
}

export default Help
