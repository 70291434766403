import { $axios } from '@/shared/api'
import { useSelector } from 'react-redux'

import LoaderFullScreen from '@/components/AppHelpers/LoaderFullScreen'
import { TopInfoBar } from '@/components/Event/TopInfoBar'
import { EventCard } from '@/components/Event/EventCard'
import { RoomCard } from '@/components/Event/RoomCard'
import Header from '@/components/Header'
import styles from './Event.module.css'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { StateRequest } from '@/shared/api/types'

const Event = () => {
  const confurmationTime = Number(process.env.REACT_APP_EVENT_CONFIRMATION_TIME) ?? 5000
  const reload = useSelector((state: any) => state.reload)
  const { event_id } = useParams()
  const [data, setData] = useState<any>(null)
  const [stateRequest, setStateRequest] = useState<StateRequest>('load')

  const confirmedEvent = () => {
    $axios.post(`/api/events/${event_id}/confirm`)
  }

  useEffect(() => {
    const timeoutId = setTimeout(confirmedEvent, confurmationTime)

    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responce = await $axios.get(`/api/events/${event_id}`)
        setData(responce.data)
        setStateRequest('success')
      } catch (e) {
        setStateRequest('error')
      }
    }

    fetchData()
  }, [reload])

  if (stateRequest === 'load') return <LoaderFullScreen />
  if (stateRequest === 'error') return <p>Возникла внутренняя ошибка сервера</p>
  if (!data) return <p>Нет данных о событии</p>

  return (
    <>
      <Header
        title='журнал событий пожарной системы'
        subtitle='для детального просмотра события нажмите на кнопку Подробнее'
      />
      <div className={styles.event__wrapper}>
        <TopInfoBar {...data.item} />
        <EventCard {...data.item} />
        {data.room?.name ? <RoomCard {...data.room} /> : null}
      </div>
    </>
  )
}

export { Event }
