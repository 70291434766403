import { CircularProgressbarStyles } from 'react-circular-progressbar/dist/types'

export const getProgressBarStyle = (color: string): CircularProgressbarStyles => {
  return {
    root: {},
    path: {
      stroke: color,
      strokeLinecap: 'round',
      transformOrigin: 'center center',
    },
    trail: {
      stroke: '#E8E8E8',
      strokeLinecap: 'butt',
      transformOrigin: 'center center',
    },
    text: {
      fill: color,
      fontSize: '16px',
    },
  }
}
