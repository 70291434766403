import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { $axios } from '@/shared/api'
import UniversalRoom from '@/components/UniversalRoom'
import './Room.css'
import LoaderFullScreen from '@/components/AppHelpers/LoaderFullScreen'

const Room = () => {
  const params = useParams()
  const roomId = params.room_id

  const [data, setData] = useState<any>(null)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [isError, setError] = useState<boolean>(false)

  const reload = useSelector((state: any) => state.reload)

  const fetchRoomId = async () => {
    await $axios
      .get(`/api/rooms/${roomId}`)
      .then(({ data }) => {
        setData(data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setError(true)
      })
  }

  useEffect(() => {
    fetchRoomId()
  }, [reload])

  if (isLoading) return <LoaderFullScreen />

  if (isError) return <p>Что то пошло не так...</p>

  return <UniversalRoom type={'room'} {...data} />
}

export default Room
