import { DeviceCard } from '@/components/DeviceCard'
import StatusCard from '@/components/StatusCard'
import { getColorStatusZones } from '@/shared/globalMethods/getColorToStatus'
import { ActiveEventCardProps } from '@/types'
import { Link } from 'react-router-dom'
import styles from './ActiveEventCard8Inch.module.css'

const ActiveEventCard8Inch = ({
  id,
  zone_num,
  status,
  devices,
  room_num,
  room_name,
}: ActiveEventCardProps) => {
  return (
    <div className={styles.card}>
      <Link
        to={`/all/zones/${id}`}
        className={`${styles.card_indicator}`}
        style={{ background: getColorStatusZones(status) }}
      >
        {zone_num}

        <span>ЗКПС</span>
      </Link>

      <div className={styles.card_devices_wrapper}>
        <div className={styles.card_devices}>
          {devices?.map(item => (
            <DeviceCard
              key={item.id}
              hardStatus={status === 'пожар1' ? 'пожар1' : ''}
              {...item}
            />
          ))}
        </div>
      </div>
      <div className={styles.text_box}>
        <p>Состояние пожаротушения</p>
        <StatusCard
          status={status}
          style={{ background: getColorStatusZones(status) }}
        />
      </div>
    </div>
  )
}

export default ActiveEventCard8Inch
