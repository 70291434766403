import { IconProps } from '@/types'

const List = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 72 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1007_19527)'>
        <path d='M9 41.7143C9 42.8189 9.89543 43.7143 11 43.7143H22.4286C23.5331 43.7143 24.4286 42.8189 24.4286 41.7143V30.2857C24.4286 29.1811 23.5331 28.2857 22.4286 28.2857H11C9.89543 28.2857 9 29.1811 9 30.2857V41.7143ZM9 61C9 62.1046 9.89543 63 11 63H22.4286C23.5331 63 24.4286 62.1046 24.4286 61V49.5714C24.4286 48.4669 23.5331 47.5714 22.4286 47.5714H11C9.89543 47.5714 9 48.4669 9 49.5714V61ZM9 22.4286C9 23.5331 9.89543 24.4286 11 24.4286H22.4286C23.5331 24.4286 24.4286 23.5331 24.4286 22.4286V11C24.4286 9.89543 23.5331 9 22.4286 9H11C9.89543 9 9 9.89543 9 11V22.4286ZM28.2857 41.7143C28.2857 42.8189 29.1811 43.7143 30.2857 43.7143H61C62.1046 43.7143 63 42.8189 63 41.7143V30.2857C63 29.1811 62.1046 28.2857 61 28.2857H30.2857C29.1811 28.2857 28.2857 29.1811 28.2857 30.2857V41.7143ZM28.2857 61C28.2857 62.1046 29.1811 63 30.2857 63H61C62.1046 63 63 62.1046 63 61V49.5714C63 48.4669 62.1046 47.5714 61 47.5714H30.2857C29.1811 47.5714 28.2857 48.4669 28.2857 49.5714V61ZM30.2857 9C29.1811 9 28.2857 9.89543 28.2857 11V22.4286C28.2857 23.5331 29.1811 24.4286 30.2857 24.4286H61C62.1046 24.4286 63 23.5331 63 22.4286V11C63 9.89543 62.1046 9 61 9H30.2857Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_1007_19527'>
          <rect
            width='54'
            height='54'
            fill='white'
            transform='translate(9 9)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default List
