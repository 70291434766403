import { MouseEventHandler } from 'react'
import styles from './CellEvent.module.css'

type TCellEvent = {
  count: number
  label: string
  status?: string
  onClick?: MouseEventHandler<HTMLDivElement>
}

const CellEvent = ({ count = 0, label, status = 'active', onClick }: TCellEvent) => {
  const getColorCard = () => count ? styles[status]: ''


  return (
    <div className={`${styles.card} ${getColorCard()}`} onClick={onClick}>
      {/* <h2>{count}</h2> */}
      <p>{label}</p>
    </div>
  )
}

export default CellEvent
