import { useNavigate } from 'react-router-dom'
import { getColorStatusDevice } from '@/shared/globalMethods/getColorToStatus'
import DeviceCardIcon from './components/DeviceCardIcon'
import type { TDevice } from '@/types'
import './DeviceCard.css'

type DeviceCardProps = TDevice & {
  hardStatus?: string // когда необходимо отобразить цвет статуса родителя, конкретнее цвет пожар1 при пожар
}

const DeviceCard = ({
  device_type,
  id,
  name,
  status,
  address,
  model,
  url,
  hardStatus,
}: DeviceCardProps) => {
  const navigate = useNavigate()
  const initialPath = window.location.pathname
  const statusForColorCard = hardStatus ? hardStatus : status

  const getNavigatePath = () => {
    if (device_type === 'ppku') {
      return `/topology/${id}`
    }

    return `${initialPath}/device/${id}`
  }

  const navigateToDevice = () => {
    if (url) {
      window.location.href = url
      return
    }

    navigate(getNavigatePath())
  }

  return (
    <div
      className={`device-card`}
      onClick={navigateToDevice}
      style={{
        borderColor: getColorStatusDevice(statusForColorCard),
        color: getColorStatusDevice(statusForColorCard),
      }}
    >
      <p className='device-card__top-text'>{status ?? 'no status'}</p>
      <DeviceCardIcon
        model={model}
        style={{
          background: getColorStatusDevice(statusForColorCard),
        }}
      />
      <p className='device-card__bottom-text'>{address ?? 'no address'}</p>
    </div>
  )
}

export { DeviceCard }
