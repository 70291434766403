import styles from './InfoBox2.module.css'

type InfoBoxProps = {
  title: string
  subtitle: string | React.ReactNode
}

const InfoBox2 = ({ title, subtitle }: InfoBoxProps) => {
  return (
    <div className={styles.box}>
      <p className={styles.title}>{title}</p>
      <div className={styles.subtitle}>{subtitle ? subtitle : 'не задано'}</div>
    </div>
  )
}

export default InfoBox2
