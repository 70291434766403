import { toastStyle } from '@/shared/stylesJS/toastStyle';
import { getDefaultStateSound } from './getDefaultStateSound'
import { toast } from 'react-toastify'

interface IAudioPlayHandler {
  audio: any | null;
}

export const audioPlayHandler = ({ audio }: IAudioPlayHandler) => {
  const volume = getDefaultStateSound()

  if (audio === null) {
    toast.error(`Компонент audio пришел null`, toastStyle)

    return
  }

  if (!audio.volume) toast.warn("У вас выключен звук", toastStyle)

  if (volume) {
    try {
      audio.play()
    } catch (error) {
      toast.error('При проигрывании звука произошла ошибка!', toastStyle)
      console.error(error)
    }
  }
}
