import { Link, useNavigate } from 'react-router-dom'
import arrowLeft from '@/assets/icons/arrow-left.svg'
import arrowLeftSlim from '@/assets/icons/arrow-left-slim.svg'
import type { THeaderBackButton } from '@/types'
import styles from './HeaderBackButton.module.css'

const HeaderBackButton = ({ backPath }: THeaderBackButton) => {
  const navigate = useNavigate()

  const handleLinkClick = (e: any) => {
    if (backPath) return

    e.preventDefault()
    navigate(-1)
  }

  if (backPath !== 'none')
    return (
      <Link
        className={styles.button}
        to={backPath ?? '/'}
        onClick={handleLinkClick}
      >
        <img src={window.innerWidth > 1300 ? arrowLeft : arrowLeftSlim } alt='<-' />
      </Link>
    )

  return null
}

export default HeaderBackButton
