import { useNavigate } from 'react-router-dom';
import { all, call, delay, put, take, takeEvery } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { Client, Message } from 'paho-mqtt'
import { addMessage } from '../reducers/mqttSlice'
import reloadSaga from './reloadSaga'

const generateRandomString= (length :number) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

const MQTT_CLIENT_ID = generateRandomString(8)
const MQTT_BROKER = process.env.NODE_ENV === 'development'
    ? String(process.env.REACT_APP_MQTT_BROKER)
    : window.location.hostname
const MQTT_PORT = process.env.NODE_ENV === 'development'
    ? Number(process.env.REACT_APP_MQTT_PORT)
    : 8080

function createMqttClient() {
  const client = new Client(MQTT_BROKER, MQTT_PORT, MQTT_CLIENT_ID)
  return client
}

function createMqttEventChannel(client: any) {
  return eventChannel(emit => {
    client.onMessageArrived = (message: any) => {
      emit(message)
    }

    return () => client.disconnect()
  })
}

function* mqttSaga(): Generator<any, void, Client> {
  while (true) {
    const client: Client = yield call(createMqttClient)

    const connectOptions = {
      onSuccess: () => {
        console.log('success paho-mqtt')
        client.subscribe('/leader/devices')
        client.subscribe('/leader/front')
      },
    }

    client.connect(connectOptions)

    yield takeEvery('mqtt/sendMessage', function* (action: any) {
      if (client.isConnected()) {
        const message = new Message(action.payload)
        message.destinationName = '/leader/mash'

        client.send(message)
      }
    })

    const channel: any = yield call(createMqttEventChannel, client)

    while (true) {
      const message: any = yield take(channel)
      const data = JSON.parse(message.payloadString)

      yield put(addMessage(message))

      // refetchData
      if (data.reload) {
        yield all([
          reloadSaga(),
          // roomsSaga(),
          // eventsCountSaga(),
        ])
      }
    }

    yield delay(2000)
  }
}

export default mqttSaga
