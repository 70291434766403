import { NumPad } from '../NumPad'
import Modal from 'react-modal'
import styles from './CustomButton.module.css'

const CustomButton = ({ title, value, onClick, disabled, style }: any) => {
  return (
    <>
      <div className={styles.wrapper} style={style}>
        <button className={styles.input} onClick={onClick} disabled={disabled}>
          {value}
        </button>
        <p>{title}</p>
      </div>
      {/* <Modal
        isOpen={isOpenModalInput}
        onRequestClose={handleClickCloseModalNumpad}
        style={modalNumPadStyles}
      >
        <NumPad
          defaultValue={value}
          onCheck={handleClickCheckModalNumpad}
          onClose={handleClickCloseModalNumpad}
        />
      </Modal> */}
    </>
  )
}

export default CustomButton
