import { SmallMenuProps } from '@/types'
import styles from './SmallMenu.module.css'

const SmallMenu = ({
  isOpen,
  reference,
  onClick,
  children,
}: SmallMenuProps) => {
  return (
    <div
      className={`${styles.wrapper} ${isOpen ? styles.open : styles.close}`}
      onClick={onClick}
      ref={reference}
    >
      <div className={styles.body}>{children}</div>
    </div>
  )
}

export default SmallMenu
