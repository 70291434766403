import { is8InchScreen } from './breakpoints'

export const defaultStyles = {
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  borderRadius: is8InchScreen ? 16 :32,
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
}

export const modalMapStyles = {
  content: {
    ...defaultStyles,
    maxWidth: is8InchScreen ? 760 : 1520,
    minHeight: is8InchScreen ? 350 : 700,
    background: '#F5F9FA',
    width: '100%',
  },
}

export const modalNumPadStyles = {
  content: {
    ...defaultStyles,
    maxWidth: 'auto',
    background: 'var(--main-color)',
    width: 'auto',
    padding: 0,
  },
}

export const modalInfoStyles = {
  content: {
    ...defaultStyles,
    maxWidth: is8InchScreen ? 450 : 900,
    minHeight: is8InchScreen ? 350 : 700,
    width: is8InchScreen ? 450 : 900,
  },
}
