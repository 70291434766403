import LoaderFullScreen from '@/components/AppHelpers/LoaderFullScreen'
import { Navigate } from 'react-router-dom'
import { DeviceCard } from '../../components/DeviceCard'
import Header from '../../components/Header'
import { Paginate } from '../../components/Paginate'
import { useQuery } from '../../shared/hooks/useQuery'
import { topologyApi } from '../../store/services/topologyApi'
import './Topology.css'

const Topology = () => {
  const {
    data: topology,
    isError,
    isLoading,
  } = topologyApi.useFetchTolopogyQuery('')

  const query = useQuery()
  const pageIndex = query.get('page') //когда-то тут будет пагинация

  if (isLoading) return <LoaderFullScreen />
  if (isError) return <p>Возникла непредвиденная ошибка</p>

  if (topology?.items.length === 1 && topology?.items[0].url) {
    window.location.href = topology?.items[0].url

    return <LoaderFullScreen />
  }

  if (topology?.items.length === 1) {
    return <Navigate to={`/topology/${topology.items[0].id}`} />
  }

  return (
    <>
      <Header
        title='корневой уровень системы'
        subtitle='для детального просмотра выберите устройство'
      />
      {topology !== null &&
      topology !== undefined ? (
        <>
          <div className='topology-grid'>
            {topology.items.map((ppku: any) => (
              <DeviceCard key={ppku.id} {...ppku} />
            ))}
          </div>

          <Paginate
            perPage={topology.per_page}
            totalPage={topology.total}
            currentPage={topology.page}
            viewedElements={topology.items.length}
            totalElements={Object.values(topology.items).length}
            // prevPage={prevPage}
            // nextPage={nextPage}
          />
        </>
      ) : (
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          Нет устройств
        </div>
      )}
    </>
  )
}

export default Topology
