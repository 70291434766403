import CustomInput from '@/components/CustomInput'
import Header from '@/components/Header'
import { ISelectOption } from '@/types'
import { $axios } from '@/shared/api'
import {
  selectConfigurateStyles,
  selectNetworkStyles,
} from '@/shared/dataComponents/selectSettingsNetwork'
import { errorsHandler } from '@/shared/globalMethods/errorsHander'
import { toastStyle } from '@/shared/stylesJS/toastStyle'
import { useState } from 'react'
import { SingleValue } from 'react-select'
import Select from 'react-select'
import { toast } from 'react-toastify'
import './AppConfigurate.css'

const AppConfigurate = () => {
  const [selectedOption, setSelectedOption] =
    useState<SingleValue<ISelectOption | null>>(null)
  const [configurateData, setConfigurateData] = useState({
    number: '',
    key: '',
  })

  const [file, setFile] = useState<any>(null)

  const getRequestBody = (value: string) => {
    if (value === 'cloud') {
      return configurateData
    }

    if (value === 'upload') {
      const formData = new FormData()
      formData.append('file', file)
      return formData
    }

    return
  }

  const handleInputFileChange = (e: any) => {
    setFile(e.target.files[0])
  }

  async function handleSaveConfigurate() {
    if (!selectedOption?.value) {
      return errorsHandler('Выберите вариант импорта')
    }

    if (selectedOption?.value === 'cloud' && !configurateData.number) {
      return errorsHandler('Введите номер конфигурации')
    }

    if (selectedOption?.value === 'cloud' && !configurateData.key) {
      return errorsHandler('Введите ключ доступа')
    }

    if (selectedOption?.value === 'upload' && !file) {
      return errorsHandler('Подгрузите файл')
    }

    await $axios.post(
      `/api/conf/${selectedOption?.value}`,
      getRequestBody(selectedOption?.value),
      selectedOption?.value === 'upload'
        ? {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        : {},
    )
  }

  function onChangeInput(name: string, value: string) {
    if (!name) {
      return
    }

    setConfigurateData(state => ({ ...state, [name]: value }))
  }

  const removedb = () => {
    $axios
      .post(`/api/db/erase`)
      .then(() => toast.success('БД успешно очищена', toastStyle))
      .catch(error => errorsHandler(error))
  }

  const demoData = () => {
    $axios
      .post(`/api/db/load_demo`)
      .then(() => toast.success('Демо данные', toastStyle))
      .catch(error => errorsHandler(error))
  }

  const handleChangeSelect = (e: SingleValue<ISelectOption | null>) => {
    setSelectedOption(e)
  }

  const options: ISelectOption[] = [
    { value: 'cloud', label: 'загрузка конфигурации из облака' },
    { value: 'upload', label: 'загрузить файл' },
    { value: 'usb', label: 'обновить конфигурацию с usb накопителя' },
  ]

  return (
    <>
      <Header title='Конфигурация' />
      <Select
        value={selectedOption}
        onChange={handleChangeSelect}
        options={options}
        className='react-select-container'
        classNamePrefix='react-select'
        styles={selectConfigurateStyles}
        placeholder='Выберите вариант импорта'
      />

      {selectedOption?.value === 'cloud' ? (
        <div className='app-configurate__wrapper'>
          <CustomInput
            value={configurateData.number}
            name='number'
            title='Номер конфигурации'
            onChange={onChangeInput}
            style={{ width: 180 }}
          />
          <CustomInput
            value={configurateData.key}
            name='key'
            title='Ключ доступа'
            onChange={onChangeInput}
          />
        </div>
      ) : null}

      {selectedOption?.value === 'upload' ? (
        <div className='app-configurate__wrapper'>
          <input type='file' onChange={handleInputFileChange} />
        </div>
      ) : null}

      <button
        className='primary-button special-button settings-network__button'
        onClick={handleSaveConfigurate}
      >
        Сохранить
      </button>
      <div className='app-configurate__buttons'>
        <button
          className='special-button settings-network__button'
          onClick={removedb}
        >
          Очистить БД
        </button>
        <button
          className='special-button settings-network__button'
          onClick={demoData}
        >
          Демо данные
        </button>
      </div>
    </>
  )
}

export default AppConfigurate
