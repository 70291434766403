import { IconProps } from '@/types'

const Tile = ({ color, size }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 72 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1007_19528)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M33.3 41.4C33.3 39.9096 32.0904 38.7 30.6 38.7H11.7C10.2096 38.7 9 39.9096 9 41.4V60.3C9 61.7904 10.2096 63 11.7 63H30.6C32.0904 63 33.3 61.7904 33.3 60.3V41.4ZM63 41.4C63 39.9096 61.7904 38.7 60.3 38.7H41.4C39.9096 38.7 38.7 39.9096 38.7 41.4V60.3C38.7 61.7904 39.9096 63 41.4 63H60.3C61.7904 63 63 61.7904 63 60.3V41.4ZM63 11.7C63 10.2096 61.7904 9 60.3 9H41.4C39.9096 9 38.7 10.2096 38.7 11.7V30.6C38.7 32.0904 39.9096 33.3 41.4 33.3H60.3C61.7904 33.3 63 32.0904 63 30.6V11.7ZM33.3 11.7C33.3 10.2096 32.0904 9 30.6 9H11.7C10.2096 9 9 10.2096 9 11.7V30.6C9 32.0904 10.2096 33.3 11.7 33.3H30.6C32.0904 33.3 33.3 32.0904 33.3 30.6V11.7Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_1007_19528'>
          <rect
            width='54'
            height='54'
            fill='white'
            transform='translate(9 9)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Tile
