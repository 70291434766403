import SidebarIcon from '@/assets/icons/Icon'
import { TSidebarItem } from '@/types'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './SidebarItem.module.css'

type SidebarItemProps = TSidebarItem & {
  isEnabled: boolean
  isActive: boolean
}

const SidebarItem = memo(
  ({
    path,
    iconName,
    title,
    primaryColor,
    secoundaryColor,
    disabledColor,
    isEnabled,
    isActive = false,
  }: SidebarItemProps) => {
    const navigate = useNavigate()

    const navigateToPage = () => {
      // если пункт меню включен (не disabled)
      if (isEnabled) {
        navigate(path)

        //сравниваем и делам активным пункт
      }

      return null
    }

    return (
      <div
        onClick={navigateToPage}
        className={styles.sidebar_item}
        style={
          isEnabled
            ? {
                color: isActive ? secoundaryColor : primaryColor,
                background: isActive ? primaryColor : secoundaryColor,
                borderColor: isActive ? secoundaryColor : primaryColor,
              }
            : {
                color: disabledColor,
                background: secoundaryColor,
                borderColor: disabledColor,
              }
        }
      >
        <SidebarIcon
          name={iconName}
          size={window.innerWidth >= 2000 ? 78 : 64}
          color={
            isEnabled
              ? isActive
                ? secoundaryColor
                : primaryColor
              : disabledColor
          }
        />
       <span>{title}</span>
      </div>
    )
  },
)

export { SidebarItem }
