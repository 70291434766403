import Header from '../../components/Header'
import type { TZoneItem } from '@/types'
import './Rooms.css'
import { Paginate } from '../../components/Paginate'
import { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import RoomCard from '@/components/RoomCard'
import { useQuery } from '@/shared/hooks/useQuery'
import { $axios } from '@/shared/api'
import { sortByStatus } from '@/shared/globalMethods/sorting'
import LoaderFullScreen from '@/components/AppHelpers/LoaderFullScreen'

const RoomList = () => {
  const reload = useSelector((state: any) => state.reload)
  const [data, setData] = useState<any>(null)
  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(false)
  const query = useQuery()

  const fetchRooms = async (status?: string | null) => {
    try {
      const { data } = await $axios.get(
        `/api/zones${status ? `?status=${status}` : ''}`,
      )
      setData(data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(true)
    }
  }

  useEffect(() => {
    fetchRooms(query.get('status'))
  }, [reload])

  useEffect(() => {
    if (query.get('status')) fetchRooms(query.get('status'))
  }, [query])

  if (isLoading) return <LoaderFullScreen />
  if (isError) return <p>Возникла ошибка</p>
  if (!data) return <p>Список комнат пуст</p>

  return (
    <>
      <Header
        title='зоны контроля пожарной сигнализации'
        subtitle='Для подробной информации выберите зону'
        backPath='/dashboard'
      />
      <div className='rooms-grid__wrapper'>
        <div className='rooms-grid'>
          {data.results.sort(sortByStatus).map((room: TZoneItem) => {
            return <RoomCard key={room.id} type={'ЗКПС'} {...room} />
          })}
        </div>
      </div>

      {window.innerWidth > 1300 && (
        <Paginate
          totalPage={data.pagination.pages}
          currentPage={data.pagination.page}
          viewedElements={data.results.length}
          perPage={data.pagination.per_page}
          // prevPage={prevPage}
          // nextPage={nextPage}
        />
      )}
    </>
  )
}

export default memo(RoomList)
