import { IconProps } from '@/types'

const Fire = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M37.0363 53.1688C42.0219 44.318 39.9345 33.1796 32.0727 26.6861C32.0682 26.6824 32.0636 26.6778 32.0599 26.6751L32.0939 26.7596L32.0884 26.8229C33.6201 30.8691 33.4052 35.311 31.5236 39.1221L30.1975 41.8091L29.7907 38.8401C29.5143 36.8262 28.7548 34.9051 27.5849 33.2604H27.4003L27.303 32.9849C27.3167 36.0622 26.6684 39.0936 25.3965 41.9533C23.7279 45.6954 23.9731 50.0107 26.0531 53.4985L27.4885 55.9063L24.9061 54.8153C20.6478 53.0163 17.2436 49.5506 15.5658 45.307C13.686 40.5684 14.0341 35.0741 16.4979 30.6129C17.7827 28.2922 18.6826 25.8284 19.1739 23.2883L19.6542 20.7987L20.8793 23.0192C21.4642 24.078 21.8949 25.2241 22.1622 26.428L22.1897 26.4565L22.2182 26.6494L22.2448 26.6411C25.9144 21.7657 28.1129 15.6992 28.4334 9.55655L28.5161 7.96509L29.8513 8.83566C35.3007 12.3868 39.0851 18.0841 40.2541 24.4858L40.2807 24.6171L40.2945 24.6373L40.3542 24.5528C41.4222 23.1423 41.9861 21.4645 41.9861 19.6985V16.9638L43.6363 19.1439C47.4731 24.2112 49.4401 30.4971 49.1757 36.8446C48.8506 44.3161 44.776 50.8739 38.2751 54.4269L35.4605 55.9651L37.0363 53.1688Z'
        fill={color}
      />
    </svg>
  )
}

export default Fire
