import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { $axios } from '@/shared/api'
import './Device.css'

import LoaderFullScreen from '@/components/AppHelpers/LoaderFullScreen'
import UniversalDeviceView from '@/components/UniversalDeviceView'

const Device = () => {
  const { id } = useParams()
  const [data, setData] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(false)

  const fetchDeviceId = async (id: string) => {
    await $axios
      .get(`/api/devices/${id}`)
      .then(({ data }: any) => {
        setData(data.item)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setError(true)
      })
  }

  const reload = useSelector((state: any) => state.reload)

  const updateData = () => {
    if (id) {
      fetchDeviceId(id)
    }
  }

  useEffect(() => {
    updateData()
  }, [reload])

  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      updateData()
    }, 60000)

    return () => clearInterval(fetchDataInterval)
  }, [])

  if (isLoading) return <LoaderFullScreen />

  if (isError) return <p>Произошла ошибка</p>

  if (!data) {
    return <p>Данных нет</p>
  }

  return <UniversalDeviceView device={data} updateData={updateData} />
}
export { Device }
