import LoaderFullScreen from '@/components/AppHelpers/LoaderFullScreen'
import { TDevice, TMash } from '@/types'
import { $axios } from '@/shared/api'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import UniversalDeviceView from '../../../components/UniversalDeviceView'
import './TopologyItem.css'

type TTopologyItem = {
  device: TDevice,
  mash: TMash[]
}

const TopologyItem = () => {
  const { ppky_id }  = useParams()
  
  const [data, setData] = useState<TTopologyItem | null>(null)
  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(false)
  const reload = useSelector((state: any) => state.reload)

  const fetchPpku = async (id: string) => {
    await $axios
      .get(`/api/topology/${id}`)
      .then(({ data }: any) => {
        setData(data)
        setLoading(false)
      })
      .catch((error: any) => {
        setLoading(false)
        setError(true)
      })
  }

  const updateData = () => {
    if (ppky_id) {
      fetchPpku(ppky_id)
    }
  }
  
  useEffect(() => {
    updateData()
  }, [reload])

  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      updateData()
    }, 60000)

    return () => clearInterval(fetchDataInterval)
  }, [])
  
  if (isLoading) return <LoaderFullScreen />

  if (isError) return <p>Возникла внутрення ошибка сервера</p>

  if (!data) return <p>Нет данных</p>

  return <UniversalDeviceView device={data.device} mash={data.mash} updateData={updateData}/>
}

export default TopologyItem
