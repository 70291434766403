import imgDelete from '@/assets/icons/numpad-delete.svg'
import imgClose from '@/assets/icons/numpad-close.svg'
import imgCheck from '@/assets/icons/numpad-check.svg'
import closeEye from '@/assets/icons/close-eye.svg'
import openEye from '@/assets/icons/open-eye.svg'
import styles from './NumPad.module.css'
import { useState } from 'react'

type NumpadProps = {
  value: string
  name: string
  onClose: Function
  onChange: Function
  type?: string
  title?: string
  onCheck?: Function
}

const NumPad = ({
  type,
  name,
  value,
  title,
  onClose,
  onChange,
  onCheck,
}: NumpadProps) => {
  const [isShowPassword, setShowPassword] = useState(false)
  const numpadNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

  const handleClickNumpadKeyDelete = () => {
    onChange(name, value.slice(0, -1))
  }

  const handleClickNumpadKeyClose = () => {
    onClose()
  }

  const handleClickNumpadKeyCheck = () => {
    onCheck === undefined ? onClose() : onCheck()
  }

  const getInputType = (isShow: boolean, typeInput?: string): string => {
    if (typeInput === 'password') {
      return isShow ? 'text' : 'password'
    }

    return 'text'
  }

  const getInputPlaceholder = (typeInput?: string): string => {
    return typeInput === 'password' ? 'Введите пароль' : 'Введите значение'
  }

  const handleClickNumpadKey = (e: any) => {
    const key = e.target.id

    if (key) {
      const newValueInput = value + key
      onChange(name, newValueInput)
    }
  }

  return (
    <>
     {title && <p className={styles.title}>{title}</p>} 
      <div className={styles.numpad}>
        <div className={styles.numpad__firstColumn}>
          <div className={styles.numpad__input}>
            <input
              value={value}
              placeholder={getInputPlaceholder(type)}
              readOnly={true}
              type={getInputType(isShowPassword, type)}
            />
            <div
              className={styles.input__toggleButton}
              onClick={() => setShowPassword(!isShowPassword)}
            >
              {type === 'password' ? (
                isShowPassword ? (
                  <img src={openEye} alt='show-password' />
                ) : (
                  <img src={closeEye} alt='hide-password' />
                )
              ) : null}
            </div>
          </div>

          <div
            className={styles.numpad__buttons}
            onClick={handleClickNumpadKey}
          >
            {numpadNumbers.map((id: number) => (
              <button key={id} id={`${id}`}>
                {id}
              </button>
            ))}
          </div>
        </div>

        <div className={styles.numpad__tools}>
          <button onClick={handleClickNumpadKeyDelete}>
            <img src={imgDelete} alt='delete' />
          </button>
          <button onClick={handleClickNumpadKeyClose}>
            <img src={imgClose} alt='close' />
          </button>
          <button onClick={handleClickNumpadKeyCheck}>
            <img src={imgCheck} alt='check' />
          </button>
        </div>
      </div>
    </>
  )
}

export { NumPad }
