import menu from '@/assets/icons/small-menu.svg'
import { $axios } from '@/shared/api'
import { useEffect, useState } from 'react'
import { OperatorStatus } from '@/types'
import LoaderFullScreen from '@/components/AppHelpers/LoaderFullScreen'
import EventCountCard from './components/EventCountCard'
import InfoBox2 from '@/components/InfoBox2'
import Header from '@/components/Header'
import { useSelector } from 'react-redux'
import './OperatorChange.css'
import Checkbox from '@/components/Checkbox'

const OperatorChange = () => {
  const reload = useSelector((state: any) => state.reload)
  const [data, setData] = useState<OperatorStatus[] | null>(null)
  const [fetchStatus, setFetchStatus] = useState<string>('load')
  const [date, setDate] = useState<Date>()

  useEffect(() => {
    const fetchData = async () => {
      await $axios
        .get('/api/operator')
        .then(({ data }) => {
          setData(data)
          setFetchStatus('success')
        })
        .catch(() => setFetchStatus('error'))
    }

    fetchData()

    setDate(new Date())
  }, [reload])

  const getTime = (date: Date): any => {
    return date.toLocaleString('ru', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })
  }

  const getDate = (date: Date): any => {
    return date.toLocaleString('ru', {
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
    })
  }

  if (fetchStatus === 'load') return <LoaderFullScreen />
  if (fetchStatus === 'error') return <p>Возникла внутренняя ошибка сервера</p>

  return (
    <>
      <Header
        title='прием-передача дежурной смены'
        subtitle='Для окончания или начала смены подпишите отчет'
      />

      <div className='operator-change__header'>
        {date && (
          <>
            <InfoBox2 title='Дата' subtitle={getDate(date)} />
            <InfoBox2 title='Время' subtitle={getTime(date)} />
            <p>Зафиксировано следующее состояние сигнализации</p>
          </>
        )}

        <img className='operator-change__menu' src={menu} alt='меню' />
      </div>

      <div className='operator-change__event-statuses'>
        {data ? (
          data.map((item: OperatorStatus) => (
            <EventCountCard key={item.event} {...item} />
          ))
        ) : (
          <p>Нет статусов</p>
        )}
      </div>

      <div className='operator-change__checkboxes'>
        <Checkbox boxLabel="пост сдал" label='Смолин В. Г.' />
      </div>
    </>
  )
}

export default OperatorChange
