import { EventItem } from '@/types'
import styles from './Table.module.css'

type TableEventProps = {
  events: EventItem[]
  total: number
  currentEvent?: string
  setCurrentEvent: Function
}

const Table = ({ events, total,currentEvent, setCurrentEvent }: TableEventProps) => {
  const handleClickEvent = (e: any) => setCurrentEvent(e.target.id)

  return (
    <div>
      <div className={styles.table} onClick={handleClickEvent}>
        <div className={styles.table__row_head}>
          <p className={styles.table__title}>К</p>
          <p className={styles.table__title}>Время</p>
          <p className={styles.table__title}>Адрес</p>
          <p className={styles.table__title}>Зона</p>
          <p className={styles.table__title}>Описание</p>
        </div>

        {events.map(({ id, event_text, created_at,confirmed_by }: EventItem) => {
          return (
            <div key={id} id={id} className={`${styles.table__row} ${currentEvent === id ? styles.table__row_active : ''}`}>
              {(confirmed_by) ? <p></p> : <p id={id} className={styles.table__cell}><span className={styles.circle}>●</span></p>}
              <p id={id} className={styles.table__cell}>{created_at}</p>
              <p id={id} className={styles.table__cell}>{'11.11.111'}</p>
              <p id={id} className={styles.table__cell}>{'001'}</p>
              <p id={id} className={styles.table__cell}>{event_text}</p>
            </div>
          )
        })}
      </div>
      <div className={styles.table__row_footer}>
        всего записей лога: <span>{total}</span>
      </div>
    </div>
  )
}

export default Table
