import Header from '@/components/Header'
import InfoBox from '@/components/InfoBox'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styles from './TimeRequests.module.css'

/**
 * Записывай значения в redux и выводи тут, все просто!
 */
const TimeRequests = () => {
  const timeRequestsSlice = useSelector((state: any) => state.timeRequest)

  useEffect(() => {
    console.log(timeRequestsSlice)

  }, [timeRequestsSlice])
  return (
    <>
      <Header title='Время выполнения запросов' />
      <div className={styles.wrapper}>
        
        {/* {timeRequestsSlice.map((request: any) => (
          <InfoBox title={request.url} subtitle={request.duration} />
        ))} */}
        {/* <InfoBox title='/api/rooms' subtitle='2.3' />
        <InfoBox title='/api/rooms/:id' subtitle='2.3' />
        <InfoBox title='/api/zones' subtitle='2.3' />
        <InfoBox title='/api/zones/:id' subtitle='2.3' />
        <InfoBox title='/api/devices/:id' subtitle='2.3' />

        <InfoBox title='/api/active-events?status=ПОЖАР2' subtitle='2.3' />
        <InfoBox title='/api/events?page=1&per-page=5' subtitle='2.3' /> */}
      </div>
    </>
  )
}

export default TimeRequests
