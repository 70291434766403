export const ObjectToArray = (data: any, fieldMainName: string) => {
  if (!data) {
    return []
  }

  const result = Object.entries(data).map(([name, options]: [string, unknown]) => ({
      [fieldMainName]: name,
      ...(options as object),
    }),
  )

  return result ?? []
}
