import { IconProps } from '@/types'

const Reload = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='IconPack-1'>
        <path
          id='Vector'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M30.169 13.3534L25.3009 20.0301C24.5003 21.1286 24.5764 22.6393 25.4847 23.6513C26.3929 24.6645 27.8853 24.9034 29.0652 24.2261L32.3097 22.3637C32.4606 22.2771 32.647 22.2771 32.7979 22.3637L36.0424 24.2261C37.2224 24.9034 38.7147 24.6645 39.6229 23.6513C40.5312 22.6393 40.6073 21.1286 39.8054 20.0301L34.9386 13.3534C34.3821 12.5908 33.4962 12.1406 32.5538 12.1406C31.6101 12.1406 30.7242 12.5908 30.169 13.3534Z'
          fill={color}
        />
        <path
          id='Vector_2'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.8308 27.5496L16.6988 20.8729C17.4995 19.7743 17.4233 18.2636 16.5151 17.2517C15.6068 16.2385 14.1145 15.9983 12.9346 16.6755L9.69008 18.5393C9.53914 18.6259 9.35276 18.6259 9.20183 18.5393L5.95733 16.6755C4.77739 15.9983 3.28508 16.2385 2.37683 17.2517C1.46858 18.2636 1.39245 19.7743 2.19439 20.8729L7.06114 27.5496C7.61764 28.3108 8.50358 28.7623 9.44595 28.7623C10.3896 28.7623 11.2756 28.3108 11.8308 27.5496Z'
          fill={color}
        />
        <path
          id='Vector_3'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M29.4158 21.7545C29.1362 21.9146 28.9538 22.202 28.9262 22.5236C28.7188 24.9334 27.8014 26.6659 26.5729 27.9022C23.7536 30.7372 19.7295 31.341 19.278 31.4014C17.7805 31.4932 16.6373 32.7598 16.7042 34.2482C16.7738 35.751 18.0469 36.9152 19.5484 36.8495C19.9658 36.8456 26.3275 36.7091 31.0275 32.727C33.5475 30.5902 35.5596 27.3772 36.0688 22.6496C36.1108 22.2611 35.9192 21.8857 35.5806 21.6902L33.7785 20.6559C33.0199 20.2202 32.088 20.2202 31.3294 20.6559L29.4158 21.7545Z'
          fill={color}
        />
        <path
          id='Vector_4'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.5843 19.1485C12.8639 18.987 13.0463 18.6996 13.0739 18.3794C13.2813 15.9696 14.1987 14.2371 15.4272 13.0007C18.2465 10.1657 22.2706 9.56199 22.7221 9.50161C24.2196 9.40974 25.3628 8.14317 25.2959 6.65349C25.2263 5.15199 23.9532 3.9878 22.4517 4.05342C22.0343 4.05736 15.6726 4.19386 10.9726 8.17599C8.45258 10.3127 6.44051 13.5257 5.93126 18.2534C5.88926 18.6405 6.08089 19.0172 6.41951 19.2115L8.22158 20.247C8.9802 20.6828 9.91208 20.6828 10.6707 20.247L12.5843 19.1485Z'
          fill={color}
        />
      </g>
    </svg>
  )
}

export default Reload
