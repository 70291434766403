import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { toastStyle } from '../../shared/stylesJS/toastStyle'

export const errosHandlerMiddleware: Middleware =
  (api: MiddlewareAPI) => next => action => {
    if (isRejectedWithValue(action)) {

    if (action.payload.originalStatus === 404) {
      toast.error(`Такой страницы нет, статус: ${action.payload.originalStatus}`, toastStyle)
      return
    }

    if (action.payload.originalStatus === 500) {
      toast.error(`Возникла внутренняя ошибка сервера :(`, toastStyle)
      return
    }

    //   toast.error(
    //     `Статус ${action.payload.originalStatus}: ${action.payload.data?.message}`,
    //     toastStyle,
    //   )
    }

    return next(action)
  }
