import { DashboardMainProps } from '@/types'
import { getProgressBarStyle } from '@/shared/stylesJS/progressBarStyle'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { useNavigate } from 'react-router-dom'
import styles from './ProgressBar.module.css'

type ProgressBarProps = {
  to: string
  type: string
  data: DashboardMainProps
}

const ProgressBar = ({ to, type, data }: ProgressBarProps) => {
  const navigate = useNavigate()

  const getTitle = (): string => {
    let title = ''

    switch (type) {
      case 'devices':
        title = 'готовность ау'
        break
      case 'security':
        title = 'готовность ЗКПС'
        break
      case 'events':
        title = 'журнал событий'
        break
      default:
        title = ''
    }

    return title
  }

  const getLeftTitle = (): string => {
    let title = ''

    switch (type) {
      case 'devices':
        title = 'неисправно'
        break
      case 'security':
        title = 'неисправно'
        break
      case 'events':
        title = 'активных'
        break
      default:
        title = ''
    }

    return title
  }

  const getRightTitle = () => {
    let title = ''

    switch (type) {
      case 'devices':
        title = 'отключено'
        break
      case 'security':
        title = 'отключено'
        break
      case 'events':
        title = 'пожарных'
        break
      default:
        title = ''
    }

    return title
  }

  const leftTitle: string = getLeftTitle()
  const rightTitle: string = getRightTitle()

  return (
    <div className={styles.progressBarComponent} onClick={() => navigate(to)}>
      <div className={styles.progressBar}>
        <h3 className={styles.title}>{getTitle()}</h3>
        <div className={styles.circularBody}>
          <CircularProgressbarWithChildren
            value={data.percent}
            styles={getProgressBarStyle(data.color)}
          >
            <div className={styles.progressBarCounters}>
              <strong style={{ color: data.color }}>{data.percent}%</strong>
              <p>{data.total}</p>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>
      <div className={styles.otherInfo}>
        <div className={styles.otherInfoCard}>
          <p>{leftTitle}</p>
          <span>{leftTitle ? data[leftTitle] : 'нет'}</span>
        </div>
        <div className={styles.otherInfoCard}>
          <p>{rightTitle}</p>
          <span>{rightTitle ? data[rightTitle] : 'нет'}</span>
        </div>
      </div>
    </div>
  )
}

export default ProgressBar
