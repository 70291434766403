import { IconProps } from '@/types'
import React from 'react'

const SixPoints = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M31.7334 36.6667C34.4948 36.6667 36.7334 34.2789 36.7334 31.3333C36.7334 28.3878 34.4948 26 31.7334 26C28.972 26 26.7334 28.3878 26.7334 31.3333C26.7334 34.2789 28.972 36.6667 31.7334 36.6667Z'
        fill={color}
      />
      <path
        d='M50.7334 36.6667C53.4948 36.6667 55.7334 34.2789 55.7334 31.3333C55.7334 28.3878 53.4948 26 50.7334 26C47.972 26 45.7334 28.3878 45.7334 31.3333C45.7334 34.2789 47.972 36.6667 50.7334 36.6667Z'
        fill={color}
      />
      <path
        d='M31.7334 53.7334C34.4948 53.7334 36.7334 51.3456 36.7334 48.4001C36.7334 45.4546 34.4948 43.0668 31.7334 43.0668C28.972 43.0668 26.7334 45.4546 26.7334 48.4001C26.7334 51.3456 28.972 53.7334 31.7334 53.7334Z'
        fill={color}
      />
      <path
        d='M50.7334 53.7334C53.4948 53.7334 55.7334 51.3456 55.7334 48.4001C55.7334 45.4546 53.4948 43.0668 50.7334 43.0668C47.972 43.0668 45.7334 45.4546 45.7334 48.4001C45.7334 51.3456 47.972 53.7334 50.7334 53.7334Z'
        fill={color}
      />
      <path
        d='M31.7334 19.6C34.4948 19.6 36.7334 17.2121 36.7334 14.2666C36.7334 11.3211 34.4948 8.93329 31.7334 8.93329C28.972 8.93329 26.7334 11.3211 26.7334 14.2666C26.7334 17.2121 28.972 19.6 31.7334 19.6Z'
        fill={color}
      />
      <path
        d='M50.7334 19.6C53.4948 19.6 55.7334 17.2121 55.7334 14.2666C55.7334 11.3211 53.4948 8.93329 50.7334 8.93329C47.972 8.93329 45.7334 11.3211 45.7334 14.2666C45.7334 17.2121 47.972 19.6 50.7334 19.6Z'
        fill={color}
      />
      <path
        d='M12.7334 36.6667C15.4948 36.6667 17.7334 34.2789 17.7334 31.3333C17.7334 28.3878 15.4948 26 12.7334 26C9.97198 26 7.7334 28.3878 7.7334 31.3333C7.7334 34.2789 9.97198 36.6667 12.7334 36.6667Z'
        fill={color}
      />
      <path
        d='M12.7334 19.6C15.4948 19.6 17.7334 17.2121 17.7334 14.2666C17.7334 11.3211 15.4948 8.93329 12.7334 8.93329C9.97198 8.93329 7.7334 11.3211 7.7334 14.2666C7.7334 17.2121 9.97198 19.6 12.7334 19.6Z'
        fill={color}
      />
      <path
        d='M12.7334 53.7334C15.4948 53.7334 17.7334 51.3456 17.7334 48.4001C17.7334 45.4546 15.4948 43.0668 12.7334 43.0668C9.97198 43.0668 7.7334 45.4546 7.7334 48.4001C7.7334 51.3456 9.97198 53.7334 12.7334 53.7334Z'
        fill={color}
      />
    </svg>
  )
}

export default SixPoints
