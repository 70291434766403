import SmallMenu from '@/components/SmallMenu'
import { $axios } from '@/shared/api'
import MenuButton from '@/components/Buttons/MenuButton'

type RoomMenuProps = {
  id: string
  isOpen: boolean
  type: string
  reference: any
  closeSmallMenu: any
  showRoomInMap: any
}

const RoomMenu = ({
  id,
  isOpen,
  type = 'room',
  reference,
  closeSmallMenu,
  showRoomInMap,
}: RoomMenuProps) => {
  const handleClickSendCommand = async (command: string) => {
    await $axios.post(`/api/${type}s/${id}/${command}`)
  }

  return (
    <SmallMenu
      isOpen={isOpen}
      reference={reference}
      onClick={() => closeSmallMenu()}
    >
      <MenuButton
        iconName='check-note'
        title={`тест помещения`}
        onClick={() => handleClickSendCommand('test')}
      />

      <MenuButton
        iconName='reset'
        title={`сброс помещения`}
        onClick={() => handleClickSendCommand('reset')}
      />

      <MenuButton
        iconName='unconnected'
        title='отключить'
        onClick={() => handleClickSendCommand('disable')}
      />

      <MenuButton
        iconName='doc-and-checkbox'
        title='снять с контроля'
        onClick={() => handleClickSendCommand('remove-from-control')}
      />

      <MenuButton
        iconName='log'
        title={`лог помещения`}
        onClick={() => handleClickSendCommand('log')}
      />

      <MenuButton
        iconName='map-point'
        title='показать на плане'
        onClick={() => {
          closeSmallMenu()
          if (showRoomInMap) showRoomInMap()
        }}
      />
    </SmallMenu>
  )
}

export default RoomMenu
