import { is8InchScreen } from '@/shared/stylesJS/breakpoints'
import { isValidElement } from 'react'
import styles from './InfoBox.module.css'

type InfoBoxProps = {
  title: string
  subtitle: string | React.ReactNode
}

const InfoBox = ({ title, subtitle }: InfoBoxProps) => {
  const subtitleIsReactElement = isValidElement(subtitle)
  
  const gap = is8InchScreen ? 8 : 16
  
  return (
    <div className={styles.box} style={{gap: subtitleIsReactElement ? gap : 0 }}>
      <p className={styles.title}>{title}</p>
      <div className={styles.subtitle}>{subtitle ? subtitle : 'не задано'}</div>
    </div>
  )
}

export default InfoBox
