import { IconProps } from '@/types'
import React from 'react'

const Unconnected = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_3837_4919)'>
        <path d='M36.3766 26.6233L32.7374 30.2625L36.3766 33.9016L32.7383 37.54L29.0991 33.9008L25.4599 37.54L29.0991 41.1791L25.4599 44.8183L33.2832 52.6416L42.3808 54.4608L47.2933 59.3733L58.2099 48.4566L53.2974 43.5441L51.4783 34.4466L43.655 26.6233L40.0158 30.2625L36.3766 26.6233Z' fill={color} />
        <path d='M14.5435 4.78992L3.62679 15.7066L8.53929 20.6191L10.3584 29.7166L18.1818 37.5399L36.3768 19.3449L28.5535 11.5216L19.456 9.70242L14.5435 4.78992Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_3837_4919'>
          <rect width='64' height='64' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Unconnected
