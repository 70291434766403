import { IconProps } from '@/types'
import React from 'react'

const DocumentDefence = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <desc>Created with Pixso.</desc>
      <defs>
        <clipPath id='clip71_4243'>
          <rect
            id='Type=documentdefence'
            width='42.000000'
            height='42.000000'
            fill='white'
            fillOpacity='0'
          />
        </clipPath>
      </defs>
      <rect
        id='Type=documentdefence'
        width='42.000000'
        height='42.000000'
        fillOpacity='0'
      />
      <g clipPath='url(#clip71_4243)'>
        <path
          id='Vector'
          d='M7.38867 26.9883L9.85693 26.9883L9.85693 29.4492L7.38867 29.4492L7.38867 26.9883Z'
          fill={color}
          fillOpacity='1.000000'
          fillRule='nonzero'
        />
        <path
          id='Vector'
          d='M7.38867 17.1445L7.38867 19.6055L9.85693 19.6055L9.85693 17.1445L7.38867 17.1445ZM23.4932 5.375C24.0508 5.31348 25.7109 5.04785 26.7412 4.55176C28.1235 3.8877 29.1729 2.75293 29.2261 2.69434C29.9097 1.92285 30.9111 1.45215 32.0405 1.47949L32.0405 1.23047C32.0405 0.550781 31.4902 0 30.8101 0L1.23047 0C0.55127 0 0 0.550781 0 1.23047L0 40.7695C0 41.4492 0.55127 42 1.23047 42L20.4824 42L20.4824 31.6729C20.4824 30.9941 21.0337 30.4424 21.7129 30.4424L32.0405 30.4424L32.0405 29.0234C32.0039 29.0244 31.9658 29.0254 31.9292 29.0254C31.3081 29.0254 30.6938 28.8623 30.1504 28.5566L30.1465 28.5547L30.1431 28.5527C27.792 27.2139 25.8027 25.4561 24.2285 23.3262C22.665 21.2109 21.5347 18.7588 20.8711 16.04C19.9023 12.0752 20.2422 8.88477 20.2837 8.53418C20.4849 6.85059 21.8296 5.54688 23.4932 5.375ZM12.3179 30.6797C12.3179 31.3594 11.7666 31.9102 11.0864 31.9102L6.15869 31.9102C5.479 31.9102 4.92773 31.3594 4.92773 30.6797L4.92773 25.7578C4.92773 25.0781 5.479 24.5273 6.15869 24.5273L11.0864 24.5273C11.7666 24.5273 12.3179 25.0781 12.3179 25.7578L12.3179 30.6797ZM12.3179 20.8359C12.3179 21.5156 11.7666 22.0664 11.0864 22.0664L6.15869 22.0664C5.479 22.0664 4.92773 21.5156 4.92773 20.8359L4.92773 15.9141C4.92773 15.2344 5.479 14.6836 6.15869 14.6836L11.0864 14.6836C11.7666 14.6836 12.3179 15.2344 12.3179 15.9141L12.3179 20.8359ZM12.3179 10.9922C12.3179 11.6719 11.7666 12.2227 11.0864 12.2227L6.15869 12.2227C5.479 12.2227 4.92773 11.6719 4.92773 10.9922L4.92773 6.07031C4.92773 5.39062 5.479 4.83984 6.15869 4.83984L11.0864 4.83984C11.7666 4.83984 12.3179 5.39062 12.3179 6.07031L12.3179 10.9922ZM7.38867 9.76172L9.85693 9.76172L9.85693 7.30078L7.38867 7.30078L7.38867 9.76172Z'
          fill={color}
          fillOpacity='1.000000'
          fillRule='nonzero'
        />
        <path
          id='Vector'
          d='M22.9434 42C23.2778 42 23.5908 41.8623 23.8135 41.6396L31.6582 33.7939C31.9219 33.5312 32.0405 33.2158 32.0405 32.9033L22.9434 32.9033L22.9434 42Z'
          fill={color}
          fillOpacity='1.000000'
          fillRule='nonzero'
        />
        <path
          id='Vector'
          d='M41.1304 8.82617C41.0674 8.29395 40.6431 7.87793 40.1099 7.82129C40.0859 7.82031 37.6987 7.56348 36.0503 6.77051C34.1504 5.85645 32.8057 4.34375 32.7925 4.33008C32.3438 3.81348 31.5293 3.80273 31.0669 4.32812C31.0537 4.34375 29.7075 5.85645 27.8076 6.77051C26.1587 7.56348 23.7725 7.82031 23.7485 7.82129C23.2153 7.87695 22.7915 8.29297 22.7275 8.82617C22.7134 8.94727 22.3804 11.8516 23.2612 15.4561C24.4395 20.2793 27.2407 24.0684 31.3604 26.4141C31.7183 26.6162 32.1499 26.6123 32.4976 26.4141C36.6187 24.0684 39.4189 20.2793 40.5972 15.4561C41.4775 11.8516 41.145 8.94727 41.1304 8.82617ZM37.2603 13.3779L32.6387 18.7314C32.165 19.2793 31.333 19.2969 30.8345 18.7988L27.8076 15.7744C27.3271 15.2939 27.3271 14.5156 27.8076 14.0352C28.2891 13.5537 29.0684 13.5537 29.5498 14.0352L31.6387 16.1211L35.3945 11.7705C35.8384 11.2568 36.6172 11.1992 37.1323 11.6426C37.6475 12.0869 37.7046 12.8633 37.2603 13.3779Z'
          fill={color}
          fillOpacity='1.000000'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}

export default DocumentDefence
