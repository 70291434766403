import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import API_BASE_URL from '../apiBaseURL'

export const deviceChartApi = createApi({
  reducerPath: 'deviceChartApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
  }),
  endpoints: build => ({
    fetchDeviceChart: build.query({
      query: (id: string) => `/devices/${id}/data/temp`,
    }),
  }),
})
