import Modal from 'react-modal'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { modalMapStyles } from '@/shared/stylesJS/modalStyle'
import planRoom from '@/assets/icons/map-example.svg'
import './ModalMap.css'
import { is8InchScreen } from '@/shared/stylesJS/breakpoints'
interface IControls {
  zoomIn: Function
  zoomOut: Function
}

const Controls = ({ zoomIn, zoomOut }: IControls) => {
  return (
    <div className='modal-controls'>
      <button onClick={() => zoomIn()}>+</button>
      <button onClick={() => zoomOut()}>-</button>
    </div>
  )
}
interface IDeviceTemp {
  data: number[]
  labels: string[]
  line: number
  title: string
}

const ModalMap = ({ isOpen, closeModal }: any) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={modalMapStyles}
    >
      <div style={{ height: is8InchScreen ? 350 : 700 }}>
        <TransformWrapper
          initialScale={1}
          minScale={0.5}
          maxScale={3}
          initialPositionX={200}
          initialPositionY={100}
          wheel={{ step: 100 }}
          pinch={{ disabled: false }}
        >
          {(utils: any) => (
            <div style={{ overflow: 'hidden' }}>
              <div className='modal__wrapper-controls'>
                <Controls {...utils} />
              </div>
              <TransformComponent>
                <div style={{ height: is8InchScreen ? 330 : 660, width: '100%', overflow: 'hidden' }}>
                  <img src={planRoom} alt='planRoom' />
                </div>
              </TransformComponent>
            </div>
          )}
        </TransformWrapper>
      </div>
    </Modal>
  )
}

export default ModalMap
