import styles from './RoundedStatusCard.module.css'

const RoundedStatusCard = ({ status, style }: any) => {
  const getTitleCard = (): string => {
    //* условные обозначения статусов
    switch (status) {
      case 'откл': {
        return 'отключение'
      }
      case 'пожар1': {
        return 'пожар 1'
      }
      case 'пожар2': {
        return 'пожар 2'
      }
      default: {
        return status
      }
    }
  }

  return (
    <div className={styles.card} style={style}>
      {getTitleCard()}
    </div>
  )
}

export default RoundedStatusCard
