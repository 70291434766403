import { errorsHandler } from '@/shared/globalMethods/errorsHander'
import { EventStatusEnum } from '@/shared/enums/event'
import { $axios } from '@/shared/api'

import styles from './GenerateTestEvent.module.css'

const GenerateTestEvent = () => {
  const sendDataTestEvent = (event: EventStatusEnum) => {
    $axios
      .post(`/api/event`, {
        event: EventStatusEnum[event],
      })
      .then(data => {
        console.log(data)
      })
      .catch(error => errorsHandler(error))
  }

  return (
    <div className={styles.card}>
      <p>Сгенерировать тестовое событие</p>
      <div className={styles.buttonsEventList}>
        <button className={styles.buttonAlarm} onClick={() => sendDataTestEvent(EventStatusEnum.alarm)}>
          Пожар
        </button>
        <button className={styles.buttonWarning} onClick={() => sendDataTestEvent(EventStatusEnum.warning)}>
          Тревога
        </button>
      </div>
    </div>
  )
}

export default GenerateTestEvent
