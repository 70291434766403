import { IconProps } from '@/types'

const Log = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.25 3.5C9.35051 3.5 7 5.85051 7 8.75V33.25C7 36.1496 9.35051 38.5 12.25 38.5H29.75C32.6496 38.5 35 36.1496 35 33.25V15.75H24.5C23.5335 15.75 22.75 14.9665 22.75 14V3.5H12.25ZM27.4874 23.9874C28.1708 23.3041 28.1708 22.1959 27.4874 21.5126C26.8041 20.8292 25.6959 20.8292 25.0126 21.5126L19.25 27.2752L16.9874 25.0126C16.304 24.3292 15.196 24.3292 14.5126 25.0126C13.8291 25.6959 13.8291 26.8041 14.5126 27.4874L18.0126 30.9874C18.696 31.6708 19.804 31.6708 20.4874 30.9874L27.4874 23.9874Z'
        fill={color}
      />
      <path
        d='M33.4623 11.7376C33.6241 11.8994 33.7743 12.0707 33.9122 12.2501H26.25V4.58789C26.4294 4.72586 26.6007 4.87601 26.7626 5.03783L33.4623 11.7376Z'
        fill={color}
      />
    </svg>
  )
}

export default Log
